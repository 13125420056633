<div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4 rounded-xl min-w-max">
    <div class="bg-white rounded-lg shadow-lg md:col-span-4 lg:col-span-12">
        <div class="p-6">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 bg-gray-50">
                <tr>

                    <th scope="col" class="px-6 py-3">
                        Plan Card No
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Sample Code
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Sample Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Description
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Design Code
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Unit Price
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Quantity
                    </th>
                </tr>
                </thead>
                <tbody>
                    @for (salesOrderItem of salesOrderItemService.all(); track salesOrderItem) {
                        <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer">
                            <td class="px-6 py-4">
                                {{ salesOrderItem.planCardNo }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.productSampleCode }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.productSampleName }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.description }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.productDesignCode }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.unitPrice }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.quantity }}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <app-table-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                                  (pageChanged)="onPageChange($event)"></app-table-pagination>
        </div>
    </div>
</div>