import {Component} from '@angular/core';
import {UserProfileComponent} from "../../../../shared/common/user-profile/user-profile.component";

@Component({
    selector: 'app-production-planning-approve',
    standalone: true,
    templateUrl: './production-planing-approve.component.html',
    styleUrl: './production-planing-approve.component.scss',
    imports: [UserProfileComponent]
})
export class ProductionPlaningApproveComponent {

}
