import {Component, computed, EventEmitter, inject, input, Output} from '@angular/core';
import {PackingList} from "../../interfaces/packing-list.entity";
import {NgClass, NgIf} from "@angular/common";
import {PackingListService} from "../../services";
import {NotificationService} from "../../../../services/notification.service";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";

@Component({
    selector: 'app-packing-list-card',
    standalone: true,
    imports: [
        NgClass,
        NgIf
    ],
    templateUrl: './packing-list-card.component.html',
    styleUrl: './packing-list-card.component.scss'
})
export class PackingListCardComponent {
    packingListInput = input.required<PackingList>()
    @Output() removeIndex = new EventEmitter<number>();
    private packingListService = inject(PackingListService);

    constructor(private notificationService: NotificationService, private dotLoadingServices: DotLoadingServices,) {
    }

    context = computed(() => {
        const packingListBreakdowns = this.packingListInput().packingListBreakdowns;
        const variationCounts: Record<string, number> = {};
        packingListBreakdowns.forEach(breakdown => {
            const key = `${breakdown.size}(${breakdown.quantity})`;
            if (variationCounts[key]) {
                variationCounts[key]++;
            } else {
                variationCounts[key] = 1;
            }
        });
        const formattedStrings = Object.entries(variationCounts).map(([key, count]) => `${key}-${count}`);
        return formattedStrings.join(', ');
    })


    onDelete() {
        if (this.packingListInput().packingListId) {
            const packId = this.packingListInput().packingListId;
            const packageIdentifier = this.packingListInput().packingListIndex || packId;

            this.notificationService.showNotification({
                type: 'alert',
                message: `Initiating deletion of package ${packageIdentifier}...`
            });

            this.dotLoadingServices.setLoading(true);
            this.packingListService.deletePacking(packId)
                .subscribe({
                    next: (res) => {
                        this.dotLoadingServices.setLoading(false);
                        this.notificationService.showNotification({
                            type: 'alert',
                            message: `Package ${packageIdentifier} has been successfully deleted.`
                        });
                    },
                    error: (err) => {
                        this.dotLoadingServices.setLoading(false);
                        let errorMessage = `Failed to delete package ${packageIdentifier}.`;

                        if (err.status === 404) {
                            errorMessage += ' The package could not be found. It may have already been deleted.';
                        }

                        this.notificationService.showNotification({
                            type: 'error',
                            message: errorMessage
                        });
                        console.error('Error deleting package:', err);
                    }
                });
        } else {
            this.notificationService.showNotification({
                type: 'alert',
                message: 'No package selected for deletion. Please select a package and try again.'
            });
        }
    }
}