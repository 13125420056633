import {Component, effect, inject, signal} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SearchCustomerComponent, UserProfileComponent} from "../../../../shared";
import {NgOptimizedImage} from "@angular/common";
import {SalesOrderItemService} from "../../../sales-order";
import {SearchSalesOrderItem} from "../../../sales-order/interfaces/sales-order-item.entity";
import {SalesOrderItemGridComponent} from "../../../sales-order/shared";
import {CreatePackingListFormComponent} from "../../shared/create-packing-list-form/create-packing-list-form.component";
import {PackingListService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {NotificationService} from "../../../../services/notification.service";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";

@Component({
    selector: 'app-packing-list',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SearchCustomerComponent,
        UserProfileComponent,
        NgOptimizedImage,
        SalesOrderItemGridComponent,
        FormsModule,
        CreatePackingListFormComponent
    ],
    templateUrl: './packing-list.component.html',
    styleUrl: './packing-list.component.scss'
})
export class PackingListComponent {

    currentView = signal<'sales_order_items' | 'create_package_form' | ''>('')

    packingListService: PackingListService = inject(PackingListService)

    searchValue: string = ''

    searchParams: SearchSalesOrderItem = {
        sales_order_id: '-1',
        plan_card_number: '',
        customer_reference: '',
        artwork_reference: '',
        description: '',
        sample_id: '-1',
        customer_id: '-1',
        items_per_page: '10',
        page_number: '1'
    }

    constructor(private toasterService: ToastrService,
                private notificationService: NotificationService,
                private dotLoadingServices: DotLoadingServices,
                private salesOrderItemService: SalesOrderItemService,
    )
    {
        this.salesOrderItemService.initial()
        this.packingListService.initial();
        effect(() => {
            if (this.salesOrderItemService.active()) {
                this.currentView.set('create_package_form')
            }
        }, {allowSignalWrites: true});
    }

    get salesOrderItem() {
        return this.salesOrderItemService.active()
    }

    search = () => {
        if (this.searchValue != '') {
            this.salesOrderItemService.initial();
            this.packingListService.initial();
            this.currentView.set('');
            this.searchParams.plan_card_number = this.searchValue
            this.dotLoadingServices.setLoading(true);
            this.salesOrderItemService.find(this.searchParams, true).subscribe({
                next: (res) => {
                    switch (res.data.totalItems) {
                        case 0:
                            this.dotLoadingServices.setLoading(false);
                            this.notificationService.showNotification({
                                type: 'error',
                                message: 'Sales Order Item not found. Please check the PP number and try again.'
                            });
                            break
                        default:
                            this.salesOrderItemService.getById(String(res.data.data[0].salesOrderItemId), true).subscribe()
                            this.currentView.set('create_package_form')
                            this.dotLoadingServices.setLoading(false);
                            break
                    }
                },
                error: (err) => {
                    this.dotLoadingServices.setLoading(false);
                    this.notificationService.showNotification({
                        type: 'error',
                        message: 'Failed to load Sales Order Item details. Please try again.'
                    });
                    console.error('Error while fetching sales order items, ', err);
                }
            })
        }else {
            this.notificationService.showNotification({
                type: 'error',
                message: 'Please enter valid PP number'
            });
        }
    }
}
