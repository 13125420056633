import {Component,inject, OnDestroy,signal} from '@angular/core';
import {SearchCustomerComponent, TablePaginationComponent, UserProfileComponent} from "../../../../shared";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SalesOrderFormComponent, SalesOrderGridComponent} from "../../../sales-order/shared";
import {SalesOrderHomeGridComponent} from "../sales-order-home-grid/sales-order-home-grid.component";
import {SalesOrderItemHomeGridComponent} from "../sales-order-item-home-grid/sales-order-item-home-grid.component";
import {NgIf} from "@angular/common";
import {SalesOrderItemService, SalesOrderService} from "../../../sales-order";


@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        UserProfileComponent,
        ReactiveFormsModule,
        SearchCustomerComponent,
        SalesOrderGridComponent,
        SalesOrderFormComponent,
        SalesOrderHomeGridComponent,
        FormsModule,
        SalesOrderItemHomeGridComponent,
        NgIf,
        TablePaginationComponent
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent {
    salesOrderService = inject(SalesOrderService);
    salesOrderItemService = inject(SalesOrderItemService);

    ppNumber = signal<string>('');
    poNumber = signal<string>('');


    totalItems: number = 0;
    itemsPerPage: number = 10;
    selectedOption: string = 'po_number';
    searchValue: string = ''
    searchType: 'po_number' | 'plan_card_number' = 'po_number';


    placeholderMap: { [key: string]: string } = {
        po_number: 'Enter Customer PO Number',
        plan_card_number: 'Enter Plan Card Number'
    };


    updatePlaceholder(event: Event) {
        const selectElement = event.target as HTMLSelectElement;
        this.selectedOption = selectElement.value;
        this.searchType = selectElement.value as 'po_number' | 'plan_card_number';
        this.searchValue = '';
        this.poNumber.set(this.searchValue)
        this.ppNumber.set(this.searchValue)
    }

    search() {
        if (this.selectedOption === 'po_number') {
            this.searchType = 'po_number';
            this.poNumber.set(this.searchValue)
            this.salesOrderService.all()
        } else if (this.selectedOption === 'plan_card_number') {
            this.searchType = 'plan_card_number';
            this.ppNumber.set(this.searchValue)
            this.salesOrderItemService.all()
        }
    }

}