import {Component, inject, OnInit} from '@angular/core';
import {SalesOrderItemService, SalesOrderService} from "../../../sales-order";
import {DeliverService} from "../../services/deliver.service";
import {APIResponse} from "../../../../../core";
import {SalesOrderData} from "../../interfaces/sales-order-item.entity";
import {finalize} from "rxjs/operators";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserProfileComponent} from "../../../../shared";
import {RouterLink} from "@angular/router";
import {DeliveryConfirmDTO} from "../../interfaces/delivery-confirmation.entity";

@Component({
    selector: 'app-delivery-confirmation',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        UserProfileComponent,
        RouterLink,
        FormsModule
    ],
    templateUrl: './delivery-confirmation.component.html',
    styleUrl: './delivery-confirmation.component.scss'
})
export class DeliveryConfirmationComponent implements OnInit {

    salesOrderService = inject(SalesOrderService)
    deliverService = inject(DeliverService)
    dotLoadingServices = inject(DotLoadingServices)
    salesOrderId: number | undefined = 0;
    deliveryConfirmDTO: DeliveryConfirmDTO[] = []

    get salesOrder() {
        return this.salesOrderService.active()
    }

    ngOnInit(): void {
        this.salesOrderId = this.salesOrder?.salesOrderId
        this.fetchDeliveryData(this.salesOrderId)
    }

    fetchDeliveryData(salesOrderId: number | undefined) {
        this.deliverService.getById(salesOrderId, true)
            .subscribe({
                next: (res) => {
                    const sConfirmationString = this.generateSConfirmation(res.data);
                    const sSurchargeString = this.generateSSurcharge(res.data);
                    const payload ={
                        System: 'PDM300',
                        PO_number: res.data.poNumber,
                        RatioMode: 'ratioMode',
                        SizeWise: 'sizeWise',
                        UserName: 'userName',
                        OriginalSupplier: 'originalSupplier',
                        PINumber: res.data.PINumber,
                        PIDate: res.data.salesOrderDate.replace(/-/g, ''),
                        S_confirmation: this.generateSConfirmation(res.data),
                        IncoTerms: 'FOB',
                        ShipPort: 'shipPort',
                        S_surcharge:this.generateSSurcharge(res.data),
                    }
                    console.log(payload)
                    return payload;
                },
                error: (err) => {
                    console.error('Error while fetching sales orders, ', err);
                }
            });
    }

     generateSSurcharge(data: any): string {
        let sSurcharge = '';
        for (const item of data.items) {
            for (const breakdown of item.breakdowns) {
                const details = breakdown.salesOrderItemBreakdownDetails;
                sSurcharge += [
                    details.materialCode,
                    '0',
                    '0',
                    '0',
                    ''
                ].join(',') + ';';
            }
        }
        
        return sSurcharge;
    }

    generateSConfirmation(data: any): string {
        let sConfirmation = '';
        this.deliveryConfirmDTO.map(item => {
            item.PO_number = data.PO_number;
            item.RatioMode = data.RatioMode;
            item.SizeWise = data.SizeWise;
            item.UserName = data.UserName;
            item.OriginalSupplier = data.OriginalSupplier;
            item.PINumber = data.PINumber;
            item.PIDate = data.PIDate;
            item.S_confirmation = '';
            item.IncoTerms = data.IncoTerms;
            item.ShipPort = data.ShipPort;
            item.S_surcharge = '';
        });
        console.log(this.deliveryConfirmDTO)

        return sConfirmation;
    }

}
