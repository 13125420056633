import {Component, computed, effect, signal} from '@angular/core';
import {SalesOrderItemService} from "../../../sales-order";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TablePaginationComponent} from "../../../../shared";
import {
    CreatePackingList,
    PackingList,
    PackingListBreakdown,
    SearchPackingList
} from "../../interfaces/packing-list.entity";
import {MaxValueDirective} from "../../../../../core/directives/max-value.directive";
import {PackingListCardComponent} from "../packing-list-card/packing-list-card.component";
import {PackingListService} from "../../services";
import {NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {NotificationService} from "../../../../services/notification.service";
import {RouterLink} from "@angular/router";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";

@Component({
    selector: 'app-create-packing-list-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TablePaginationComponent,
        FormsModule,
        MaxValueDirective,
        PackingListCardComponent,
        NgForOf,
        NgIf,
        NgClass,
        RouterLink,
        NgSwitch,
        NgSwitchCase
    ],
    templateUrl: './create-packing-list-form.component.html',
    styleUrl: './create-packing-list-form.component.scss'
})
export class CreatePackingListFormComponent {

    searchParams: SearchPackingList = {
        sales_order_item_id: '-1',
        invoice_id: '-1',
        customer_id: '-1',
        packing_list_status: '',
        items_per_page: '0',
        page_number: '0'
    }

    packingLists: PackingList[] = []
    unsavedPackingLists = signal<PackingList[]>([]);

    constructor(
        private salesOrderItemService: SalesOrderItemService,
        private packingListService: PackingListService,
        private notificationService: NotificationService,
        private dotLoadingServices: DotLoadingServices
    ) {
        effect(() => {
            const salesOrderItem = this.salesOrderItemService.active()
            if (salesOrderItem) {
                this.createPackingList.salesOrderItemId = salesOrderItem.salesOrderItemId
                this.searchParams.sales_order_item_id = String(salesOrderItem.salesOrderItemId)
                this.packingListService.find(this.searchParams, true).subscribe()
                this.updatePackingListsFromService();
            }
        });
        effect(() => {
            const packingLists = this.packingListService.all()
            if (packingLists) {
                packingLists.forEach(packingList => {
                    let plBrs: PackingListBreakdown[] = []
                    packingList.packingListBreakdowns.forEach(packingListBreakdown => {
                        plBrs.push({
                            packIndex: packingListBreakdown.packIndex,
                            quantity: packingListBreakdown.packQuantity,
                            size: packingListBreakdown.salesOrderItemBreakdown.size,
                            salesOrderItemBreakdownId: packingListBreakdown.salesOrderItemBreakdown.salesOrderItemBreakdownId
                        })
                    })
                    this.packingLists.push({
                        packingListId: packingList.packingListId,
                        packingListIndex: packingList.packingListIndex,
                        packingListReference: packingList.packingListReference,
                        packingListStatus: packingList.packingListStatus,
                        packingListBreakdowns: plBrs
                    })
                })
            }
        });
    }

    createPackingList: CreatePackingList = {salesOrderItemId: -1, packingLists: []}

    salesOrderItemsBreakdowns = computed(() => {
        return this.salesOrderItemService.active()?.salesOrderItemBreakdowns
            .map((breakdown) => (
                    {
                        ...breakdown,
                        checked: false,
                        pendingQty: breakdown.salesOrderItemBreakdownQuantity - breakdown.packedQuantity,
                        prepProcessedQty: 0,
                        processingQty: 0,
                        balanceQty: breakdown.salesOrderItemBreakdownQuantity - breakdown.packedQuantity
                    }
                )
            ).map((breakdown) => {
                    const {salesOrderItemBreakdownDetails, salesOrderItemId, ...rest} = breakdown
                    return rest
                }
            );
    })



    process = () => {
        let lastIndex = Math.max(...this.packingLists.map(pl => pl.packingListIndex),
            ...this.unsavedPackingLists().map(pl => pl.packingListIndex), 0);
        let packingList: PackingList = {

            packingListId: -1,
            packingListReference: '',
            packingListStatus: '',
            packingListIndex: lastIndex + 1,
            packingListBreakdowns: []
        }
        this.salesOrderItemsBreakdowns()?.map((salesItemBreakdown) => {
            if (salesItemBreakdown.processingQty > 0) {
                if (salesItemBreakdown.processingQty > salesItemBreakdown.balanceQty) {
                    salesItemBreakdown.processingQty = salesItemBreakdown.balanceQty;
                }
                packingList.packingListBreakdowns.push({
                    packIndex: packingList.packingListBreakdowns.length + 1,
                    quantity: salesItemBreakdown.processingQty,
                    size: salesItemBreakdown.salesOrderItemBreakdownSize,
                    salesOrderItemBreakdownId: salesItemBreakdown.salesOrderItemBreakdownId
                });
                salesItemBreakdown.prepProcessedQty = salesItemBreakdown.processingQty;
                salesItemBreakdown.balanceQty = salesItemBreakdown.balanceQty - salesItemBreakdown.processingQty;
                salesItemBreakdown.processingQty = 0;
                salesItemBreakdown.checked = false;
            }
        });
        this.unsavedPackingLists().push(packingList);
    }

    updatePackingListsFromService() {
        const fetchedPackingLists = this.packingListService.all();
        this.packingLists = fetchedPackingLists.map(packingList => ({
            packingListId: packingList.packingListId,
            packingListIndex: packingList.packingListIndex,
            packingListReference: packingList.packingListReference,
            packingListStatus: packingList.packingListStatus,
            packingListBreakdowns: packingList.packingListBreakdowns.map(breakdown => ({
                packIndex: breakdown.packIndex,
                quantity: breakdown.packQuantity,
                size: breakdown.salesOrderItemBreakdown.size,
                salesOrderItemBreakdownId: breakdown.salesOrderItemBreakdown.salesOrderItemBreakdownId
            }))
        }));
    }


    remove = (index: number) => {
        const totalQuantities: Record<number, number> = {};
        for (const breakdown of this.packingLists[index].packingListBreakdowns) {
            const {salesOrderItemBreakdownId, quantity} = breakdown;
            if (!totalQuantities[salesOrderItemBreakdownId]) {
                totalQuantities[salesOrderItemBreakdownId] = 0;
            }
            totalQuantities[salesOrderItemBreakdownId] += quantity;
        }

        this.salesOrderItemsBreakdowns()?.map((salesItemBreakdown) => {
            if (salesItemBreakdown.salesOrderItemBreakdownId in totalQuantities) {
                let quantity = totalQuantities[salesItemBreakdown.salesOrderItemBreakdownId]
                salesItemBreakdown.prepProcessedQty = salesItemBreakdown.prepProcessedQty - quantity
                salesItemBreakdown.balanceQty = salesItemBreakdown.balanceQty + quantity
            }
        })

        if (index > -1 && index < this.createPackingList.packingLists.length) {
            this.createPackingList.packingLists.splice(index, 1);
        }

        this.createPackingList.packingLists.forEach((data, index) => {
            data.packingListIndex = index
        })

    }

    save = () => {
        if (this.unsavedPackingLists().length > 0) {
            this.createPackingList.packingLists = this.unsavedPackingLists();
            this.dotLoadingServices.setLoading(true);
            this.packingListService.create(this.createPackingList).subscribe({
                next: (res) => {
                    const savedCount = this.unsavedPackingLists().length;
                    this.unsavedPackingLists.set([]);
                    this.notificationService.showNotification({
                        type: 'success',
                        message: `Successfully saved ${savedCount} packing list${savedCount > 1 ? 's' : ''}`
                    });
                    // Re fetch the data
                    const activeItem = this.salesOrderItemService.active();
                    if (activeItem) {
                        this.packingListService.find({
                            sales_order_item_id: String(activeItem.salesOrderItemId),
                            invoice_id: '-1',
                            customer_id: '-1',
                            packing_list_status: '',
                            items_per_page: '0',
                            page_number: '0'
                        }, true).subscribe({
                            next: () => {
                                this.updatePackingListsFromService();
                                this.dotLoadingServices.setLoading(false);
                            },
                            error: (err) => {
                                this.dotLoadingServices.setLoading(false);
                                this.notificationService.showNotification({
                                    type: 'error',
                                    message: 'Failed to refresh Packing List details. Please try again.'
                                });
                                console.error('Error while refreshing packing lists, ', err);
                            }
                        });
                    }
                },
                error: (err) => {
                    this.dotLoadingServices.setLoading(false);
                    this.notificationService.showNotification({
                        type: 'error',
                        message: 'Failed to save packing list. Please try again.'
                    });
                    console.error('Error while saving packing list, ', err);
                }
            });
        } else {
            this.notificationService.showNotification({
                type: 'alert',
                message: 'No changes to save'
            });
        }
    }

    removeUnsaved(packingListIndex: number) {
        const index = this.unsavedPackingLists().findIndex(pl => pl.packingListIndex === packingListIndex);
        if (index === -1) return;
        const packingList = this.unsavedPackingLists()[index];
        const totalQuantities: Record<number, number> = {};
        for (const breakdown of packingList.packingListBreakdowns) {
            const {salesOrderItemBreakdownId, quantity} = breakdown;
            if (!totalQuantities[salesOrderItemBreakdownId]) {
                totalQuantities[salesOrderItemBreakdownId] = 0;
            }
            totalQuantities[salesOrderItemBreakdownId] += quantity;
        }

        this.salesOrderItemsBreakdowns()?.map((salesItemBreakdown) => {
            if (salesItemBreakdown.salesOrderItemBreakdownId in totalQuantities) {
                let quantity = totalQuantities[salesItemBreakdown.salesOrderItemBreakdownId];
                salesItemBreakdown.prepProcessedQty = salesItemBreakdown.prepProcessedQty - quantity;
                salesItemBreakdown.balanceQty = salesItemBreakdown.balanceQty + quantity;
            }
        });

        const updatedLists = this.unsavedPackingLists().filter(pl => pl.packingListIndex !== packingListIndex);
        this.unsavedPackingLists.set(updatedLists);
    }

    selectAll(event: MouseEvent): void {
        (event.target as HTMLInputElement).select()
    }

    toggleAllAndFillQuantities(event: any) {
        const isChecked = event.target.checked;
        this.salesOrderItemsBreakdowns()?.forEach(item => {
            if (item.balanceQty > 0) {
                item.checked = isChecked;
                if (isChecked) {
                    item.processingQty = item.balanceQty;
                } else {
                    item.processingQty = 0;
                }
            }
        });
    }

    onCheckboxChange(salesOrderItemBreakdown: any) {
        if (salesOrderItemBreakdown.checked) {
            salesOrderItemBreakdown.processingQty = salesOrderItemBreakdown.balanceQty;
        } else {
            salesOrderItemBreakdown.processingQty = 0;
        }
    }

    hasItemsWithBalance(): boolean {
        return <boolean>this.salesOrderItemsBreakdowns()?.some(item => item.balanceQty > 0);
    }

    hasProcessingQty = () => {
        return this.salesOrderItemsBreakdowns()?.some(item => item.processingQty > 0);
    }

    currentFilter: 'all' | 'unsaved' | 'saved' = 'all';

    onFilterChange(event: Event) {
        this.currentFilter = (event.target as HTMLSelectElement).value as 'all' | 'unsaved' | 'saved';
    }
}
