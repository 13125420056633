<div class="grid grid-cols-1 gap-4 px-4 py-2 md:grid-cols-12 rounded-xl">
    <div class="bg-white rounded-xl flex justify-center items-center p-6 md:hidden">
        <app-user-profile></app-user-profile>
    </div>
    <div class="bg-white rounded-xl flex flex-col justify-center items-center p-6 md:col-span-9">
        <form class="space-y-4 w-full">
            <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
                <div>
                    <input type="date" id="start-range" name="start-date" (ngModelChange)="onStartDateChange()"
                           class="input-new" placeholder="Select Start Date" [(ngModel)]="startDate" required>
                </div>
                <div>
                    <input type="date" id="end-range" name="end-date" class="input-new" placeholder="Select End Date"
                           [min]="startDate" [(ngModel)]="endDate" required>
                </div>
            </div>
        </form>
    </div>
    <div class="bg-white rounded-xl col-span-3 items-center h-full p-6">
        <app-user-profile></app-user-profile>
    </div>
</div>
<div class="px-4 py-6">
    <div class="bg-white rounded-lg shadow-lg p-6">
        <div class="mb-6">
            @if (startDate || endDate) {
                <label for="date-range" class="block text-sm font-medium text-gray-700 mb-2">Selected Date Range:</label>
                <p id="date-range" class="text-lg font-semibold text-[#D41A6A]">{{ startDate }}  -  {{ endDate }}</p>
            } @else {
                <label for="date-range" class="block text-sm font-medium text-gray-700 mb-2">&nbsp;</label>
                <p class="text-lg font-semibold ">Please Select the Date Range to Generate Reports.</p>
            }
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- Sales Order Report Card -->
            <div class="border border-gray-200 rounded-md p-4 hover:bg-gray-50 transition duration-150">
                <div class="flex flex-col h-full justify-between">
                    <div>
                        <h3 class="text-lg font-semibold text-gray-700 mb-2">Sales Order Report</h3>
                        <p class="text-sm text-gray-600 mb-4">Generate and download the sales order report for the selected date range.</p>
                    </div>
                    <button
                            [class]="!startDate || !endDate ? 'report-enabled' : (salesOrderReportGenerated ? 'report-enabled' : 'report-enabled')"
                            (click)="salesOrderReportGenerated ? salesOrderReport() : generateReport('salesOrder')"
                            [disabled]="!startDate || !endDate"
                    >
                        {{ salesOrderReportGenerated ? 'Download' : 'Generate' }}
                    </button>
                </div>
            </div>
            <!-- Invoice Report Card -->
            <div class="border border-gray-200 rounded-md p-4 hover:bg-gray-50 transition duration-150">
                <div class="flex flex-col h-full justify-between">
                    <div>
                        <h3 class="text-lg font-semibold text-gray-700 mb-2">Invoice Report</h3>
                        <p class="text-sm text-gray-600 mb-4">Generate and download the invoice report for the selected date range.</p>
                    </div>
                    <button
                            [class]="!startDate || !endDate ? 'report-enabled' : (invoiceReportGenerated ? 'report-enabled' : 'report-enabled')"
                            (click)="invoiceReportGenerated ? invoiceReport() : generateReport('invoice')"
                            [disabled]="!startDate || !endDate"
                    >
                        {{ invoiceReportGenerated ? 'Download' : 'Download' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>