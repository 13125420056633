import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {APIRequestResources, CachedAPIRequest} from "../../../../core";
import {BehaviorSubject, catchError} from "rxjs";
import {take, tap} from "rxjs/operators";
import {toSignal} from "@angular/core/rxjs-interop";
import {CreatePackingList, PackingListDTO,} from "../interfaces/packing-list.entity";
import {handleError} from "../../../../core/util/util";


@Injectable({
  providedIn: 'root'
})
export class PackingListService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<PackingListDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  constructor(protected override http: HttpClient, private router: Router,) {
    super(http, APIRequestResources.PackingListService);
    this.find({
      sales_order_item_id: '',
      invoice_id: '',
      packing_list_status: 'P',
      customer_id: '',
      items_per_page: 10,
      page_number: 1
    }, true).pipe(take(1)).subscribe();
  }

  find = (params: any, refresh = true) => {
    return this.get<PackingListDTO[]>({
          endpoint: `find`,
          params: params
        },
        refresh ? 'freshness' : 'performance')
        .pipe(
            tap(res => this.$all.next(res.data ?? [])),
            catchError(handleError)
        )
  }

  create(packingList: CreatePackingList) {
    return this.post<any>(packingList, {});
  }

  initial() {
    this.$all.next([]);
  }

  deletePacking(id: number) {
    return this.delete<number>({
      endpoint: `remove/${id}`
    });
  }

}
