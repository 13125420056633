<div class="container mx-auto p-6 space-y-4" *ngIf="packingListInput()?.packingListId">
  <div class="flex-auto block w-full p-6 bg-white border border-gray-200 rounded-lg ">
    <div class="flex justify-between items-center">
      <h6 class="mb-2 font-bold tracking-tight text-gray-900">Package No. {{ packingListInput().packingListIndex }}</h6>
      <p [ngClass]="[
      'font-bold', 'text-xs', 'px-2', 'py-1', 'rounded-md',
      packingListInput().packingListId === -1 ? 'bg-orange-500 text-white' : '',
      packingListInput().packingListStatus === 'P' ? 'bg-red-500 text-white' : '',
      packingListInput().packingListStatus === 'D' ? 'bg-blue-500 text-white' : ''
   ]">
        {{ packingListInput().packingListId === -1 ? 'Unsaved' :
        packingListInput().packingListStatus === 'P' ? 'Pending' :
          packingListInput().packingListStatus === 'D' ? 'Delivered' : '' }}
      </p>
    </div>

    @if (packingListInput().packingListId > 0) {
      <p class="font-normal text-gray-700 dark:text-gray-400">{{ packingListInput().packingListReference }}</p>
    }

    <div>
      <p class="font-normal text-gray-700 dark:text-gray-400">{{ context() }}</p>
    </div>

    @if (packingListInput().packingListId === -1) {
      <div class="flex justify-end mt-4">
        <button class="button mb-4 max-w-32 max-h-10" (click)="removeIndex.emit(packingListInput().packingListIndex)">Cancel</button>
      </div>
    } @else if (packingListInput().packingListStatus === 'P') {
      <div class="flex justify-end mt-4">
        <button class="button mb-4 max-w-32 max-h-10" (click)="onDelete()">Delete</button>
      </div>
    }
  </div>
</div>
