<div class="flex">
    <div class="main-container flex-1">
        <div class="col-span-9 sub-container h-fit">
            <div class="relative overflow-x-auto rounded-xl m-7">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead class="text-xs text-gray-700 bg-gray-50">
                    <tr>
                        <th scope="col" class="p-4 ">
                            <div class="flex items-center">
                                @if (hasItemsWithBalance()) {
                                    <input id="checkbox-all" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                           (change)="toggleAllAndFillQuantities($event)">
                                    <label for="checkbox-all" class="sr-only">checkbox</label>
                                }
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">Size</th>
                        <th scope="col" class="px-6 py-3">Order QTY</th>
                        <th scope="col" class="px-6 py-3">Delivered QTY</th>
                        <th scope="col" class="px-6 py-3">Packed QTY</th>
                        <th scope="col" class="px-6 py-3">Pending QTY</th>
                        <th scope="col" class="px-6 py-3">Processed QTY</th>
                        <th scope="col" class="px-6 py-3">Quantity</th>
                        <th scope="col" class="px-6 py-3">Balance QTY</th>
                    </tr>
                    </thead>
                    <tbody>
                        @for (salesOrderItemBreakdown of salesOrderItemsBreakdowns(); track salesOrderItemBreakdown) {
                            <tr class="bg-white border-b hover:bg-gray-50">
                                <td class="w-4 p-4">
                                    @if (salesOrderItemBreakdown.balanceQty > 0) {
                                        <div class="flex items-center">
                                            <input id="checkbox-table-search-2" name="salesOrderId" type="checkbox"
                                                   [(ngModel)]="salesOrderItemBreakdown.checked"
                                                   (change)="onCheckboxChange(salesOrderItemBreakdown)"
                                                   [disabled]="salesOrderItemBreakdown.balanceQty == 0"
                                                   class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded ">
                                            <label for="checkbox-table-search-2" class="sr-only">checkbox</label>
                                        </div>
                                    }
                                </td>
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {{ salesOrderItemBreakdown.salesOrderItemBreakdownSize }}
                                </td>
                                <td class="w-44 px-6 py-4">
                                    {{ salesOrderItemBreakdown.salesOrderItemBreakdownQuantity }}
                                </td>
                                <td class="px-6 py-4 items-center">
                                    {{ salesOrderItemBreakdown.deliveredQuantity }}
                                </td>
                                <td class="px-6 py-4 items-center">
                                    {{ salesOrderItemBreakdown.packedQuantity }}
                                </td>
                                <td class="px-6 py-4 items-center">
                                    {{ salesOrderItemBreakdown.pendingQty }}
                                </td>
                                <td class="px-6 py-4 items-center">
                                    {{ salesOrderItemBreakdown.prepProcessedQty }}
                                </td>
                                <td class="w-44 px-6 py-4 items-center">
                                    @if (salesOrderItemBreakdown.balanceQty > 0) {
                                        <input class="form-input w-full text-center" type="number"
                                               [(ngModel)]="salesOrderItemBreakdown.processingQty"
                                               (click)="selectAll($event)" [min]="1"
                                               [setMaxValue]="salesOrderItemBreakdown.balanceQty">
                                    }
                                </td>
                                <td class="px-6 py-4 items-center">
                                    {{ salesOrderItemBreakdown.balanceQty }}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div class="main-container">
                <div class="col-span-2 sub-container grid grid-cols-1">
                    <div class="py-2">
                        @if (hasProcessingQty()) {
                            <button (click)="process()" class="button-new">Process Package</button>
                        } @else {
                            <button class="disabled" disabled>Process Package</button>
                        }
                    </div>
                </div>
            </div>
        </div>

        <div class="col-span-3 sub-container overflow-y-auto h-[calc(90vh-100px)]">

            <div *ngIf="unsavedPackingLists().length > 0" class="mb-4 pt-4 px-10 min-h-12">
                <select (change)="onFilterChange($event)" class="input-new ">
                    <option value="all">All Packaging Lists</option>
                    <option value="unsaved">Unsaved Packaging Lists</option>
                    <option value="saved">Saved Packaging Lists</option>
                </select>
            </div>
            <div [ngSwitch]="currentFilter" class="container mx-auto px-4">
                <div *ngSwitchCase="'all'">
                    <div *ngIf="unsavedPackingLists().length > 0" class="mb-6">
                        <div class="space-y-4">
                            <div *ngFor="let packingList of unsavedPackingLists()" class="w-full">
                                <app-packing-list-card [packingListInput]="packingList"
                                                       (removeIndex)="removeUnsaved($event)"
                                                       class="block w-full"></app-packing-list-card>
                            </div>
                        </div>
                        <div class="flex justify-center mt-6">
                            <button type="button"
                                    class="button-new w-full sm:w-auto max-w-52 min-h-8 px-6 py-2 rounded-md shadow-sm"
                                    (click)="save()">Save Packages
                            </button>
                        </div>
                    </div>
                    <div>
                        <hr class="border-t border-gray-300 my-4">
                        <div class="space-y-4">
                            <div *ngFor="let packingList of packingLists" class="w-full">
                                <app-packing-list-card [packingListInput]="packingList"
                                                       (removeIndex)="remove($event)"
                                                       class="block w-full"></app-packing-list-card>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Only Unsaved Lists -->
                <div *ngSwitchCase="'unsaved'">
                    <div class="space-y-4">
                        <div *ngFor="let packingList of unsavedPackingLists()" class="w-full">
                            <app-packing-list-card [packingListInput]="packingList"
                                                   (removeIndex)="removeUnsaved($event)"
                                                   class="block w-full"></app-packing-list-card>
                        </div>
                    </div>
                    <div class="flex justify-center mt-6">
                        <button type="button"
                                class="button-new w-full sm:w-auto max-w-52 min-h-8 px-6 py-2 rounded-md shadow-sm"
                                (click)="save()">Save Packages
                        </button>
                    </div>
                </div>
                <div *ngSwitchCase="'saved'">
                    <hr class="border-t border-gray-300 my-4">
                    <div class="space-y-4">
                        <div *ngFor="let packingList of packingLists" class="w-full">
                            <app-packing-list-card [packingListInput]="packingList"
                                                   (removeIndex)="remove($event)"
                                                   class="block w-full"></app-packing-list-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>