@if(isVisible){
  <div class=" main ">
    <div class="main-container ">
      <div class="close-button" (click)="close()">
        <img src="../../../../../../assets/icons/close-icon.svg" alt="X">
      </div>
      <div class="grid grid-cols-1 md:grid-cols-4 mx-4 md:mx-8 min-w-16">
        <div class="sub-container col-span-4 grid grid-cols-2 md:grid-cols-4 place-items-baseline">
          <div class="bg-[#F9F9F9] p-6 rounded-xl w-full md:w-auto text-center h-auto whitespace-normal ">
            <span class="text">Request Date Already Available</span>
          </div>
          <div></div>
          <div class="">
            <button class="pink-button">Update</button>
          </div>
          <div class="">
            <button class="pink-button">Accept</button>
          </div>
        </div>
        <div class="table-container  mt-6 col-span-4">
          <div class="sub-container col-span-4 md:col-span-3">
            <div class="relative overflow-x-auto  rounded-xl ">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Plan Card
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Label Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Style
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Length
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Quantity
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Request Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Width
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="bg-white border-b hover:bg-gray-50">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Microsoft Surface Pro
                  </th>
                  <td class="px-6 py-4">
                    White
                  </td>
                  <td class="px-6 py-4">
                    Laptop PC
                  </td>
                  <td class="px-6 py-4">
                    No
                  </td>
                  <td class="w-44 px-6 py-4">
                    Laptop PC
                  </td>
                  <td class="w-44 px-6 py-4">
                    Laptop PC
                  </td>
                  <td class="w-44 px-6 py-4">
                    Laptop PC
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-span-4 grid grid-cols-1 md:grid-cols-4 gap-2 p-4 bg-[#FCFCFC] mx-4 md:mx-8 rounded-xl">
          <div>
            <span class="text">Delivery Date</span>
            <input type="date" class="form-input">
          </div>
          <div>
            <span class="text">Production Plant</span>
            <input type="text" class="form-input">
          </div>
          <div>
            <span class="text">Priority</span>
            <input type="text" class="form-input">
          </div>
          <div>
            <span class="text">Request Date</span>
            <input type="date" class="form-input">
          </div>
        </div>
      </div>
    </div>
  </div>
}
