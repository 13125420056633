<div class="header-container">
    <div class="grid grid-cols-4 col-span-3 sub-container">
        <div class="form-group w-full col-span-4 justify-center flex">
            <div class="py-1" style="flex-grow: 1;">
                <label class="block">
                    <span class="sr-only">Choose file</span>
                    <input type="file"
                           accept=".xml"
                           class="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0
        file:text-sm file:font-[520] file:bg-[#ffcbe1] file:text-[#D41A6A] hover:file:bg-[#930046] hover:file:text-white cursor-pointer"/>
                </label>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-3 col-span-6 sub-container">
<!--        <div class="flex pl-4 ">-->
<!--            <select class="  input-new min-w-64 " >-->
<!--                <option value="po_item" selected>Purchase Order Item</option>-->
<!--                <option value="material_code">Material Code</option>-->
<!--            </select>-->
<!--        </div>-->
        <div class="flex py-1">
                <button class="button flex py-1">Download Items</button>
        </div>
        <div class="flex py-1">
            <button [routerLink]="'/sales-order'" class="button flex py-1">Sales Order</button>
        </div>


    </div>
    <div class="col-span-3 p-6 sub-container">
        <app-user-profile></app-user-profile>
    </div>
</div>

<div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4 rounded-xl min-w-max">
    <div class="bg-white rounded-lg shadow-lg md:col-span-4 lg:col-span-12">
        <div class="p-5">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 bg-gray-50">
                <tr>
                    <th scope="col" class="px-5 py-3">
                        Customer Po Number
                    </th>
                    <th scope="col" class="px-5 py-3">
                        PI Number
                    </th>
                    <th scope="col" class="px-5 py-3">
                        PI Date
                    </th>
                    <th scope="col" class="px-5 py-3">
                        Material Code
                    </th>
                    <th scope="col" class="px-5 py-3">
                       Quantity
                    </th>
                    <th scope="col" class="px-5 py-3">
                        Customer Request Date
                    </th>
<!--                    <th scope="col" class="px-6 py-3">-->
<!--                        Customer Request Date-->
<!--                    </th>-->
                </tr>
                </thead>
                <tbody>
<!--                    @for (salesOrderItem of itemViewList; track salesOrderItem) {-->
                        <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer">
                            <th scope="row" class="px-6 py-4">
<!--                                {{ salesOrderItem.customerReference }}-->
                            </th>
                            <td class="px-6 py-4">
<!--                                {{ salesOrderItem.description }}-->
                            </td>
                            <td class="px-6 py-4">
<!--                                {{ salesOrderItem.styleNo }}-->
                            </td>
                            <td class="px-6 py-4">
<!--                                {{ salesOrderItem.quantity }}-->
                            </td>
                            <td class="px-6 py-4">
<!--                                {{ salesOrderItem.netPrice }}-->
                            </td>
                            <td class="px-6 py-4">
<!--                                {{ salesOrderItem.unitPrice }}-->
                            </td>
                            <td class="px-6 py-4">
<!--                                {{ salesOrderItem.customerRequestDate }}-->
                            </td>
                        </tr>
<!--                    }-->
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="main-container">
    <div class="col-span-2 sub-container grid grid-cols-1">
        <div class="py-2">
            <button [routerLink]="'/sales-order'" class="button-new">Back</button>
        </div>
    </div>
</div>



