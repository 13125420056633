import {Injectable} from "@angular/core";
import {APIRequestResources, CachedAPIRequest} from "../../../../core";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {BehaviorSubject, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {SalesOrderData} from "../interfaces/sales-order-item.entity";

@Injectable({
    providedIn: 'root'
})
export class DeliverService extends CachedAPIRequest {

    private readonly $active = new BehaviorSubject<SalesOrderData | undefined>(undefined);
    active = toSignal(this.$active, {initialValue: undefined})

    constructor(protected override http: HttpClient, private router: Router) {
        super(http, APIRequestResources.DeliveryService);
    }

    getById = (id: any, refresh = false) => {
        return this.get<SalesOrderData>({id}, refresh ? 'freshness' : 'performance')
            .pipe(
                tap((res) => this.$active.next(res.data)),
            )
    }


}