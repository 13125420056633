<div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4 rounded-xl">
  <div class="bg-white rounded-lg shadow-lg md:col-span-4 lg:col-span-12">
    <div class="p-6">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 bg-gray-50">
        <tr>
          <th scope="col" class="p-4">
          </th>
          <th scope="col" class="px-6 py-3">
            Plan Card Number
          </th>
          <th scope="col" class="px-6 py-3">
            Customer PO Number
          </th>
          <th scope="col" class="px-6 py-3">
            Description
          </th>
          <th>
            Quantity
          </th>
          <th scope="col" class="px-6 py-3">
            Delivery Address
          </th>
        </tr>
        </thead>
        <tbody>
                  @for (salesOrderItem of salesOrderItems(); track salesOrderItem) {
                    <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer">
                      <td class="w-4 p-4">
                        <div class="flex items-center">
                          <input id="checkbox-table-search-2" name="salesOrderId" type="checkbox" [value]="salesOrderItem.salesOrderItemId"
                                 class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <label for="checkbox-table-search-2" class="sr-only">checkbox</label>
                        </div>
                      </td>
                      <th scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-900">
                        {{ salesOrderItem.planCardNumber }}
                      </th>
                      <td class="px-6 py-4">
                        {{ salesOrderItem.poNumber }}
                      </td>
                      <td class="px-6 py-4">
                        {{ salesOrderItem.description }}
                      </td>
                      <td>
                        {{ salesOrderItem.quantity }}
                      </td>
                      <td class="px-6 py-4">
                        {{ salesOrderItem.deliveryAddressId  }}
                      </td>
                    </tr>
                  }
        </tbody>
      </table>
    </div>
  </div>
</div>
