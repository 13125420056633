<div class="grid grid-cols-1 gap-4 px-4 py-2 md:grid-cols-12 rounded-xl">
    <div class="bg-white rounded-xl flex justify-center items-center p-6 md:hidden">
        <app-user-profile></app-user-profile>
    </div>

    <div class="bg-white rounded-xl flex flex-col justify-center items-center p-6 md:col-span-9">
        <form class="space-y-4 w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <div>
                    <input type="date" [(ngModel)]="dateRange" id="date-range" name="date-range" class="input-new"
                           placeholder="Select Date Range">
                </div>
                <div>
                    <app-search-customer></app-search-customer>
                </div>
                <div>
                    <input type="search" [(ngModel)]="invoiceNumber" id="invoice-number"
                           (input)="onInvoiceNumberInput($event)"
                           name="invoice-number" class="input-new" placeholder="Enter Invoice Number">
                </div>
                <div class="flex space-x-2">
                    <button type="submit" (click)="searchInvoices()" class="button-new">Search</button>
                    <button type="button" (click)="createInvoice()" class="button-new">Create Invoice</button>
                </div>
            </div>
        </form>
    </div>
    <div class="bg-white rounded-xl col-span-3  items-center h-full p-6">
        <app-user-profile></app-user-profile>
    </div>
</div>

<!-- Card with table -->
<div class="px-4">
    <div class=" bg-white rounded-lg shadow-lg p-6 md:col-span-12">
        <div class="overflow-x-auto ">
            <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 bg-gray-50">
                <tr>
                    <th scope="col" class="px-6 py-3">Invoice Date</th>
                    <th scope="col" class="px-6 py-3">Invoice Number</th>
                    <th scope="col" class="px-6 py-3">Customer Name</th>
                    <th scope="col" class="px-6 py-3">PO Number</th>
                    <th scope="col" class="px-6 py-3">Status</th>
                    <th scope="col" class="px-6 py-3">Packing List</th>
                    <th scope="col" class="px-6 py-3">Invoice</th>
                </tr>
                </thead>
                <tbody>
                    @for (invoice of searchResults; track invoice) {


                        <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer">
                            <td class="px-6 py-4 font-medium text-gray-900">{{ invoice.invoiceDate }}</td>
                            <td class="px-6 py-4 font-medium text-gray-900">{{ invoice.invoiceNumber }}</td>
                            <td class="px-6 py-4 font-medium text-gray-900">{{ invoice.customerName }}</td>
                            <td class="px-6 py-4 font-medium text-gray-900">{{ invoice.invoicePO }}</td>
                            <td class="px-6 py-4 font-medium text-gray-900">{{ invoice.invoiceStatus }}</td>
                            <td class="px-6 py-4">
                                <button [ngClass]="{
                'bg-[#D41A6A] hover:bg-[#930046]': invoice.invoiceStatus !== 'cancel',
                'bg-gray-400': invoice.invoiceStatus === 'cancel'
            }"
                                        class="text-white font-normal py-2 px-4 rounded"
                                        [disabled]="invoice.invoiceStatus === 'cancel'"
                                        (click)="packingListDownload(invoice.invoiceId)">
                                    Download
                                </button>
                            </td>
                            <td class="px-6 py-4">
                                <button class="bg-[#D41A6A] hover:bg-[#930046] text-white font-normal py-2 px-4 rounded"
                                        (click)="invoiceDownload(invoice.invoiceId)">Download
                                </button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <app-table-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                                  (pageChanged)="onPageChange($event)"></app-table-pagination>
        </div>
    </div>
</div>