import {Component, input} from '@angular/core';
import {SalesOrderItem} from "../../interfaces/invoice.entity";

@Component({
  selector: 'app-sales-order-item-grid',
  standalone: true,
  imports: [],
  templateUrl: './sales-order-item-grid.component.html',
  styleUrl: './sales-order-item-grid.component.scss'
})
export class SalesOrderItemGridComponent {

  salesOrderItems  = input.required<SalesOrderItem[]>()

}
