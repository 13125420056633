import {Component, effect, inject, signal} from '@angular/core';
import {SalesOrderItemFormComponent, SalesOrderItemGridComponent, SalesOrderItemHeadComponent} from "../../shared";
import {SalesOrderItemDTO, SearchSalesOrderItem} from "../../interfaces/sales-order-item.entity";
import {SalesOrderItemService, SalesOrderService} from "../../services";
import {FormsModule} from "@angular/forms";
import {SearchCustomerComponent, UserProfileComponent} from "../../../../shared";
import {SalesOrderDTO} from "../../interfaces/sales-order.entity";
import {Router, RouterLink} from "@angular/router";

@Component({
  selector: 'app-sales-order-item',
  standalone: true,
  templateUrl: './sales-order-item.component.html',
  styleUrl: './sales-order-item.component.scss',
  imports: [
    SalesOrderItemHeadComponent,
    SalesOrderItemFormComponent,
    SalesOrderItemGridComponent,
    FormsModule,
    SearchCustomerComponent,
    UserProfileComponent,
    RouterLink
  ]
})
export class SalesOrderItemComponent {

  currentView = signal<'search' | 'result' | 'create' | 'update'>('result');

  salesOrderService: SalesOrderService = inject(SalesOrderService);
  salesOrderItemService: SalesOrderItemService = inject(SalesOrderItemService);

  salesOrderItemId = signal(0);


  salesOrder: SalesOrderDTO | undefined
  salesOrderItem: SalesOrderItemDTO | undefined


  searchParams: SearchSalesOrderItem = {
    sales_order_id: '-1',
    plan_card_number: '',
    customer_reference: '',
    artwork_reference: '',
    description: '',
    customer_id:'-1',
    sample_id: '-1',
    items_per_page: '10',
    page_number: '1'
  }

  constructor(private router: Router) {
    effect(() => {
      this.salesOrder = this.salesOrderService.active()
      if (!this.salesOrder) {
        this.router.navigate(['sales-order'])
      }
    });
    effect(() => {
      this.salesOrderItem = this.salesOrderItemService.active()
    });
  }

}
