import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from "@angular/router";
import {MASPortalApiRequest, MASPortalResources} from "../../../../core";
import {BehaviorSubject, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {MASPurchaseOrderDTO} from "../interfaces/mas-purchase-order.entity";

@Injectable({
  providedIn: 'root'
})
export class OrderDownloadService extends MASPortalApiRequest {

  private readonly $all = new BehaviorSubject<MASPurchaseOrderDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, MASPortalResources.PurchaseOrderDownloadService)
  }

  find = (searchParams: any) => {
    return this.get<MASPurchaseOrderDTO[]>({
      params: searchParams,
    })
      .pipe(
        tap((res) => {
          return this.$all.next(res.data);
        })
      )
  }

  initial  = () => {
    this.$all.next([])

  }

  uploadFile(file: any){
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post<MASPurchaseOrderDTO[]>(formData, {})
  }


}
