<div class="card-main-container">
    <div class="col-span-1 sub-container">
        <div class="form-group">
            <select class="input-new" (change)="updatePlaceholder($event)">
                <option value="po_number">Customer PO</option>
                <option value="plan_card_number">Plan Card</option>
            </select>
        </div>
    </div>
    <div class="grid grid-cols-2 col-span-2 sub-container">
        <div class="form-group w-full">
            <div>
                <input class="input-new" [(ngModel)]="searchValue" [placeholder]="placeholderMap[selectedOption]"
                       type="search">
            </div>
        </div>
        <div class="form-group w-full">
            <div>
                <button class="button-new" (click)="search()">Search</button>
            </div>
        </div>
    </div>
    <div class="col-span-1 sub-container">
        <app-user-profile></app-user-profile>
    </div>
</div>

<div>

    <app-sales-order-home-grid
            *ngIf="searchType === 'po_number'"
            [searchResult]="this.poNumber()">
    </app-sales-order-home-grid>

    <app-sales-order-item-home-grid
            *ngIf="searchType === 'plan_card_number'"
            [ppNumber]="this.ppNumber()">
    </app-sales-order-item-home-grid>


</div>


