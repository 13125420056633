<div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4 rounded-xl">
  <div class="bg-white rounded-lg shadow-lg md:col-span-4 lg:col-span-12">
    <div class="p-6">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-xs text-gray-700 bg-gray-50">
        <tr>
          <th scope="col" class="p-4">
          </th>
          <th scope="col" class="px-6 py-3">
            Sales Order Reference
          </th>
          <th scope="col" class="px-6 py-3">
            Sales Order Date
          </th>
          <th scope="col" class="px-6 py-3">
            PO Number
          </th>
          <th>
            Customer
          </th>
          <th scope="col" class="px-6 py-3">
            Currency
          </th>
          <th scope="col" class="px-6 py-3">
            PO Value
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
<!--          @for (salesOrder of salesOrders; track salesOrder) {-->
<!--            <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer"-->
<!--                (click)="handleSalesOrderClick(salesOrder.salesOrderId)">-->
<!--              <td class="w-4 p-4">-->
<!--                <div class="flex items-center">-->
<!--                  <input id="checkbox-table-search-2" name="salesOrderId" type="radio" [value]="salesOrder.salesOrderId" [(ngModel)]="selectedSalesOrderId"-->
<!--                         class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">-->
<!--                  <label for="checkbox-table-search-2" class="sr-only">checkbox</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <th scope="row"-->
<!--                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-900">-->
<!--                {{ salesOrder.salesOrderReference }}-->
<!--              </th>-->
<!--              <td class="px-6 py-4">-->
<!--                {{ salesOrder.salesOrderDate }}-->
<!--              </td>-->
<!--              <td class="px-6 py-4">-->
<!--                {{ salesOrder.poNumber }}-->
<!--              </td>-->
<!--              &lt;!&ndash;                @if (searchParams.customer_id === '-1') {&ndash;&gt;-->
<!--              <td>{{ salesOrder.customer }}</td>-->
<!--              &lt;!&ndash;                }&ndash;&gt;-->
<!--              <td class="px-6 py-4">-->
<!--                {{ salesOrder.currency }}-->
<!--              </td>-->
<!--              <td class="px-6 py-4">-->
<!--                {{ salesOrder.poValue }}-->
<!--              </td>-->
<!--              <td class="px-6 py-4">-->
<!--                @if (salesOrder.salesOrderStatus === 'active') {-->
<!--                  <div-->
<!--                    class="bg-[#F3F3F3] text-[#424735] font-medium px-2 py-1 rounded-lg inline-flex items-center">-->
<!--                    <div class="w-2 h-2 mr-2 bg-[#CDF463] rounded-full"></div>-->
<!--                    Active-->
<!--                  </div>-->
<!--                }-->
<!--                @if (salesOrder.salesOrderStatus === 'inactive') {-->
<!--                  <div-->
<!--                    class="bg-[#F3F3F3] text-[#FF4D4D] font-medium px-2 py-1 rounded-lg inline-flex items-center">-->
<!--                    <div class="w-2 h-2 mr-2 bg-[#FF4D4D] rounded-full"></div>-->
<!--                    Inactive-->
<!--                  </div>-->
<!--                }-->
<!--              </td>-->
<!--            </tr>-->
<!--          }-->
        </tbody>
      </table>
    </div>
  </div>
</div>
