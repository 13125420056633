import {NgOptimizedImage} from '@angular/common';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-production-planning-popup',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './production-planing-popup.component.html',
  styleUrl: './production-planing-popup.component.scss'
})
export class ProductionPlaningPopupComponent {
  @Input() isVisible: boolean = false ;
  isSuccess: boolean = false;

  displaypopup() {
    this.isVisible = true;
    console.log('popup work')
  }

  close() {
    this.isVisible = false;
  }
}
