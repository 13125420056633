import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {SearchCustomerComponent, TablePaginationComponent, UserProfileComponent} from "../../../../shared";
import {
    ExtractedData,
    InvoiceDTO,
    InvoiceResults,
    PackingList,
    PrintInvoiceItem
} from "../../interfaces/invoice.entity";
import {InvoiceService} from "../../services";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf} from "@angular/common";
import {CustomerService} from "../../../../services";
import {PackingListService} from "../../../packing-list";
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';
import toWords from 'number-to-words';
import {Router, RouterLink} from "@angular/router";
import {NotificationService} from "../../../../services/notification.service";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";
import {take} from "rxjs";

@Component({
    selector: 'app-invoice-view',
    standalone: true,
    imports: [
        SearchCustomerComponent,
        FormsModule,
        NgForOf,
        RouterLink,
        UserProfileComponent,
        TablePaginationComponent,
        NgClass
    ],
    templateUrl: './invoice-view.component.html',
    styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnInit {

    customerService = inject(CustomerService);
    packingListService = inject(PackingListService);

    totalItems: number = 0;
    itemsPerPage: number = 0;
    dateRange: string = '';
    customerName = signal('');
    invoiceNumber: string = '';
    searchResults: InvoiceResults[] = [];
    originalSearchResults: InvoiceResults[] = [];
    invoicePackingLists: any[] = [];
    extractedData: ExtractedData[] = [];

    searchParams: any = {
        start_date: '',
        end_date: '',
        invoice_number: '',
        customer_id: '',
        invoice_type_id: '',
        items_per_page: '10',
        page_number: '1'

    };

    constructor(
        private dotLoadingServices: DotLoadingServices,
        private notificationService: NotificationService,
        private invoiceService: InvoiceService,
        private router: Router
    ) {
        effect(() => {
            const customer = this.customerService.active();
            this.customerName.set(<string>customer?.customerName)
        }, {allowSignalWrites: true});

    }

    ngOnInit() {
        this.fetchInvoices(this.searchParams);
    }

    fetchInvoices(params: any) {
        this.dotLoadingServices.setLoading(true);
        this.invoiceService.find(params).subscribe({
            next: (response) => {
                this.searchResults = response.data.data;
                this.originalSearchResults = response.data.data;
                this.totalItems = response.data.totalItems;
                this.itemsPerPage = response.data.itemsPerPage
                this.dotLoadingServices.setLoading(false);
            },
            error: (error) => {
                console.error(error);
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Error occurred while fetching invoices'
                });
            }
        });
    }

    createInvoice() {
        this.dotLoadingServices.setLoading(true);
        this.packingListService.find({
            sales_order_item_id: '',
            invoice_id: '',
            packing_list_status: 'P',
            customer_id: '',
            items_per_page: 50,
            page_number: 1
        }, true).pipe(take(1)).subscribe({
            next: () => {
                this.dotLoadingServices.setLoading(false);
                this.router.navigate(['/invoice-create']);
            },
        });
    }

    searchInvoices() {
        this.searchParams = {
            ...this.searchParams,
            date: this.dateRange,
            customer_name: this.customerName(),
            invoice_number: this.invoiceNumber.replace(/\s/g, ''),
            page_number: '1' // Reset to first page on new search
        };
        this.fetchInvoices(this.searchParams);
    }

    getSearchCriteria(): string {
        let criteria = [];
        if (this.dateRange) {
            criteria.push(`Date ${this.dateRange}`);
        }
        if (this.customerName()) {
            criteria.push(`Customer ${this.customerName()}`);
        }
        if (this.invoiceNumber) {
            criteria.push(`Invoice Number ${this.invoiceNumber}`);
        }

        if (criteria.length === 0) {
            return "the given criteria";
        } else {
            return criteria.join(", ");
        }
    }

    onInvoiceNumberInput(event: any) {
        const inputValue = event.target.value;
        const trimmedValue = inputValue.replace(/\s/g, '');

        if (inputValue !== trimmedValue) {
            event.target.value = trimmedValue;
            this.invoiceNumber = trimmedValue;
        }
    }

    onPageChange(pageNumber: number) {
        this.fetchInvoices({
            ...this.searchParams,
            page_number: String(pageNumber)
        });
    }

    invoiceDownload(id: number) {
        this.dotLoadingServices.setLoading(true);
        this.invoiceService.getById(id, true).subscribe({
            next: (res) => {
                this.generatePdf(res.data)
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'success',
                    message: `Invoice ${res.data.invoiceNumber} Downloaded Successfully`
                });
            },
            error: (err) => {
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'error',
                    message: `Error Occurred While Downloading Invoice`
                });
                console.error(err)
                this.dotLoadingServices.setLoading(false);
            }
        });
    }

    packingListDownload(invoiceId: number) {
        const searchPackingParams = {
            sales_order_item_id: '',
            invoice_id: invoiceId,
            packing_list_status: 'D',
            customer_id: '',
            items_per_page: '50',
            page_number: '1'
        };
        this.packingListService.find(searchPackingParams).subscribe({
            next: (res) => {
                this.dotLoadingServices.setLoading(true);
                this.invoicePackingLists = res.data as unknown as PackingList[];
                this.extractedData = this.invoicePackingLists.flatMap(item => {
                    return item.packingListBreakdowns.map((bd: any, index: number) => {
                        this.invoiceNumber = item.invoice.invoiceNumber
                        const details = bd.salesOrderItemBreakdown.salesOrderItemBreakdownDetails;
                        return [
                            item.salesOrderItem.salesOrder.poNumber,
                            details.purchaseOrderItem,
                            details.materialCode,
                            item.packingListBreakdowns[index]?.salesOrderItemBreakdown?.size,
                            item.packingListBreakdowns[index]?.packQuantity,
                            item.salesOrderItem.salesOrder.salesOrderReference,
                            item.invoice.invoiceNumber,
                            item.salesOrderItem.planCardNumber,
                            item.invoice.dispatchNote,
                            item.packingListReference,
                            item.invoice.invoiceNumber,
                            item.salesOrderItem.unitPrice * 1000,
                            1000,
                        ];
                    });
                });
                this.exportToCSV(this.invoiceNumber, this.extractedData)
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'success',
                    message: `Packing List of Invoice Number ${this.invoiceNumber} Downloaded Successfully`
                });
            },
            error: (err) => {
                this.dotLoadingServices.setLoading(false);
                console.error(err);
                this.notificationService.showNotification({
                    type: 'error',
                    message: `Error Occurred While Downloading Packing List`
                });
                this.dotLoadingServices.setLoading(false);
            }
        });
    }

    exportToCSV(invoiceNumber: any, data: any[]): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
        const csvData: string = XLSX.utils.sheet_to_csv(worksheet);
        const blob: Blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `PL_${invoiceNumber}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    async generatePdf(invoice: InvoiceDTO) {

        const invoiceItemsList: Record<number, PrintInvoiceItem> = {}
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, invoice.invoiceNumber, {format: 'CODE128'});
        invoice.invoiceItems.map(invoiceItem => {
            if (!invoiceItemsList[invoiceItem.salesOrderItemBreakdown.salesOrderItemId]) {
                invoiceItemsList[invoiceItem.salesOrderItemBreakdown.salesOrderItemId] = {
                    hsCode: '',
                    poli: invoiceItem.salesOrderItemBreakdown.salesOrderItemBreakdownDetails.purchaseOrderItem,
                    poNumber: invoiceItem.poNumber,
                    description: invoiceItem.salesOrderItemBreakdown.soiDescription,
                    quantity: invoiceItem.quantity,
                    unitPrice: invoiceItem.unitPrice,
                }
            } else {
                invoiceItemsList[invoiceItem.salesOrderItemBreakdown.salesOrderItemId].quantity += invoiceItem.quantity
            }
        })

        let totalValue = 0
        let totalQuantity = 0
        const rowData: any[] = []
        Object.values(invoiceItemsList).map(item => {
            const amount = (Math.round((item.unitPrice * item.quantity) * 10000) / 10000)
            console.log((Math.round((item.unitPrice * 1000) * 1000) / 10000).toFixed(4))
            rowData.push([
                item.poli,
                item.poNumber,
                item.description,
                {
                    text: item.quantity,
                    alignment: 'right'
                },
                {
                    text: (Math.round((item.unitPrice * 1000) * 10000) / 10000).toFixed(4),
                    alignment: 'right',
                    margin: [0, 0, 5, 0]
                },
                {
                    text: amount.toFixed(4),
                    alignment: 'right',
                    margin: [0, 0, 5, 0]
                },
            ])
            totalValue += amount
            totalQuantity += item.quantity
        })
        totalValue = Math.round(totalValue * 100) / 100

        const documentDefinition = {
            pageMargins: [25, 80, 25, 40],
            background: function (currentPage: any, pageSize: { width: any; height: any; }) {
                return [
                    {
                        canvas: [
                            {type: 'rect', x: 20, y: 20, w: pageSize.width - 40, h: 1, color: 'black'}, // Top border
                            {type: 'rect', x: 20, y: 80, w: pageSize.width - 40, h: 1, color: 'black'}, // Top border
                            {
                                type: 'rect',
                                x: 20,
                                y: pageSize.height - 40,
                                w: pageSize.width - 40,
                                h: 1,
                                color: 'black'
                            }, // Footer Top border
                            {
                                type: 'rect',
                                x: 20,
                                y: pageSize.height - 20,
                                w: pageSize.width - 40,
                                h: 1,
                                color: 'black'
                            }, // Bottom border
                            {type: 'rect', x: 20, y: 20, w: 1, h: pageSize.height - 40, color: 'black'}, // Left border
                            {
                                type: 'rect',
                                x: pageSize.width - 21,
                                y: 20,
                                w: 1,
                                h: pageSize.height - 40,
                                color: 'black'
                            }, // Right border
                        ]
                    },
                    { // Text overlay (absolute positioning)
                        alignment: 'center',
                        text: 'This is a system generated invoice. No signature required.',
                        fontSize: 8,
                        color: 'black',
                        bold: true,
                        margin: [0, -15, 0, 0],
                    },
                    {
                        absolutePosition: {x: 30, y: 30},
                        text: 'PT MAXIM SMART MANUFACTURING INDONESIA',
                        fontSize: 18,
                        color: 'black',
                        bold: true
                    },
                    {
                        absolutePosition: {x: 30, y: 50},
                        text: '38, Jl. Bhumimas VIII, Talagasari, Kec. Cikupa, Kabupaten Tangerang, Banten 15710, Indonesia.',
                        fontSize: 8,
                        color: 'black',
                        bold: true
                    },
                    {
                        absolutePosition: {x: 30, y: 62},
                        text: ' NPWP16 : 0608551586451000',
                        fontSize: 8,
                        color: 'black',
                        bold: true
                    }
                ];
            },
            content: [
                {image: canvas.toDataURL('image/png'), width: 100, height: 50, absolutePosition: {x: 470, y: 25}},
                {
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                {
                                    text: invoice.invoiceStatus === 'cancel' ? 'This is cancelled invoice' : invoice.invoiceType.invoiceTypeName,
                                    bold: true,
                                    color: invoice.invoiceStatus === 'cancel' ? 'red' : 'black',
                                    border: [false, false, false, true]
                                },
                                {
                                    text: invoice.invoiceNumber, bold: true, border: [false, false, false, true]
                                },
                                {
                                    text: invoice.invoiceDate, bold: true, border: [false, false, false, true]
                                }
                            ]
                        ]
                    },
                    alignment: 'center',
                    margin: [-5, 0, -5, 5]
                },
                {
                    columns: [
                        [
                            {text: 'Customer Name', bold: true, fontSize: 10, margin: [5, 0, 0, 0]},
                            {
                                canvas: [
                                    {height: 2}
                                ]
                            },
                            {text: invoice.invoiceCustomerName, fontSize: 10, margin: [5, 5, 0, 0]},
                            {text: invoice.customerVATNo, fontSize: 8, margin: [5, 0, 0, 0]}
                        ],
                        [
                            {text: 'Bill To:', underlined: true, bold: true, fontSize: 10},
                            {
                                canvas: [
                                    {type: 'line', x1: 0, y1: 1, x2: 30, y2: 1, lineWidth: 1} // Adjust coordinates and width
                                ]
                            },
                            {
                                text: invoice.invoiceAddressText,
                                fontSize: 10,
                                margin: [0, 5, 0, 0]
                            }
                        ],
                        [
                            {text: 'Ship To:', underlined: true, bold: true, fontSize: 10},
                            {
                                canvas: [
                                    {type: 'line', x1: 0, y1: 1, x2: 40, y2: 1, lineWidth: 1} // Adjust coordinates and width
                                ]
                            },
                            {
                                text: invoice.deliveryAddressText,
                                fontSize: 10,
                                margin: [0, 5, 0, 0]
                            }
                        ]
                    ]

                },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto'],
                        body: [
                            [
                                {text: 'POLI', style: 'tableHeader'},
                                {text: 'PO No', style: 'tableHeader'},
                                {text: 'Description', style: 'tableHeader'},
                                {text: 'Quantity', style: 'tableHeader'},
                                {text: 'Unit Price(1000)', style: 'tableHeader'},
                                {text: 'Amount(USD)', style: 'tableHeader'},
                            ],
                            ...rowData,
                            [
                                {text: '', border: [false, false, false, true], style: 'cellContent'},
                                {text: '', border: [false, false, false, true], style: 'cellContent'},
                                {
                                    text: 'Total ',
                                    alignment: 'right',
                                    border: [false, false, true, true],
                                    style: 'tableHeader'
                                },
                                {
                                    text: totalQuantity,
                                    alignment: 'right',
                                    border: [false, false, false, true],
                                    style: 'cellContent'
                                },
                                {
                                    text: 'Sub Total ',
                                    alignment: 'right',
                                    border: [true, false, true, true],
                                    style: 'tableHeader'
                                },
                                {
                                    text: (Math.round(totalValue * 100) / 100).toFixed(2),
                                    alignment: 'right',
                                    border: [true, false, false, true],
                                    margin: [0, 0, 5, 0],
                                    style: 'cellContent'
                                },
                            ]
                        ]
                    }
                },
                {
                    stack: [
                        {
                            text: [
                                {
                                    text: ' Invoice Value (USD) : ', bold: true,
                                },
                                this.amountInWords(totalValue).toUpperCase(),
                                ' ONLY.'
                            ],
                            fontSize: 10,
                            margin: [0, 10, 0, 5]
                        },
                        {
                            text: [
                                {
                                    text: ' Payment Terms : ', bold: true,
                                },
                                'T/T NET 30 DAYS (the bank charge is by Maxim)'
                            ],
                            fontSize: 10,
                            margin: [0, 5, 0, 10]
                        },
                    ],
                },
                {
                    text: 'THE GOODS ARE OF INDONESIA ORIGIN ', bold: true, fontSize: 10, margin: [0, 10, 0, 10]
                },
                {
                    text: 'Bank account for remittance:', bold: true, fontSize: 10, margin: [0, 10, 0, 10]
                },
                {
                    table: {
                        body: [
                            [
                                {
                                    text: 'Beneficiary :', bold: true, border: [false, false, false, false]
                                },
                                {
                                    text: 'PT MAXIM SMART MANUFACTURING INDONESIA',
                                    bold: true,
                                    border: [false, false, false, false]
                                },
                            ],
                            [
                                {
                                    text: 'Account No :', bold: true, border: [false, false, false, false]
                                },
                                {
                                    text: '1082706911-USD', border: [false, false, false, false]
                                },
                            ],
                            [
                                {
                                    text: 'SWIFT :', bold: true, border: [false, false, false, false]
                                },
                                {
                                    text: 'CENAIDJA', border: [false, false, false, false]
                                },
                            ],
                            [
                                {
                                    text: 'Bank Name :', bold: true, border: [false, false, false, false]
                                },
                                {
                                    text: 'PT Bank Central Asia branch Tangerang',
                                    bold: true,
                                    border: [false, false, false, false]
                                },
                            ],
                            [
                                {
                                    text: 'Bank Address :', bold: true, border: [false, false, false, false]
                                },
                                {
                                    text: 'JL. KISAMAUN NO 57, TANGERANG, BANTEN, INDONESIA',
                                    bold: true,
                                    border: [false, false, false, false]
                                },
                            ]
                        ]
                    },
                    fontSize: 8,
                    margin: [-5, 0, -5, 5]
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [-5, 10, -5, 0],
                    alignment: 'center',
                    fontSize: 10
                },
                tableOpacityExample: {
                    margin: [0, 0, 0, 0],
                    fillColor: 'blue',
                    fillOpacity: 0.3
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black',
                    alignment: 'center'
                },
                cellContent: {
                    fontSize: 10,
                    color: 'black',
                    alignment: 'center'
                }
            },
        };
        // @ts-ignore
        pdfMake.createPdf(documentDefinition, null, null, pdfFonts.pdfMake.vfs).open();
    }

    amountInWords(totalValue: number) {
        const dollars = Math.floor(totalValue);
        const cents = Math.round((totalValue - dollars) * 100);
        const dollarsInWords = toWords.toWords(dollars) + (dollars === 1 ? ' dollar' : ' dollars');
        const centsInWords = cents > 0 ? toWords.toWords(cents) + (cents === 1 ? ' cent' : ' cents') : '';

        return dollarsInWords + (centsInWords ? ' and ' + centsInWords : '');
    }


}
