import {Injectable} from '@angular/core'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import { HttpClient } from '@angular/common/http'
import {Router} from '@angular/router'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {BehaviorSubject, tap} from 'rxjs'
import {toSignal} from "@angular/core/rxjs-interop";
import {SearchCustomerReferenceDTO} from "../interface/customer-reference.entity";

@Injectable({
  providedIn: 'root'
})
export class CustomerReferenceService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<SearchCustomerReferenceDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<SearchCustomerReferenceDTO | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.CustomerReferenceService)
  }

  getById = (id: any, refresh = false) => {
    return this.get<SearchCustomerReferenceDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  find = (params: any, refresh = false) => {
    return this.get<SearchCustomerReferenceDTO[]>({ endpoint: `find`, params:params }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data)),
      )
  }

}
