<div class="main-container">
  <div class="sub-container col-span-1">
    <div>
      <input type="text" name="" id="" placeholder="Plan Card Number" class="form-input">
    </div>
  </div>
  <div class="sub-container col-span-2 grid grid-cols-3">
    <div>
      <button class="sub-button">Submit</button>
    </div>
    <div>
      <button class="button">Update</button>
    </div>
    <div>
<!--      <button class="button" (click)="popup.displaypopup()">Clear</button>-->
    </div>
  </div>
  <div class="sub-container">
    <app-user-profile></app-user-profile>
  </div>
</div>
<div class="table-container mt-6">
  <div class="sub-container col-span-3">
    <div class="relative overflow-x-auto  rounded-xl ">
      <table class="w-full text-sm text-left text-gray-500  ">
        <thead class="text-xs text-gray-700 bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3">
              Plan Card
            </th>
            <th scope="col" class="px-6 py-3">
              Label Name
            </th>
            <th scope="col" class="px-6 py-3">
              Style
            </th>
            <th scope="col" class="px-6 py-3">
              Length
            </th>
            <th scope="col" class="px-6 py-3">
              Quantity
            </th>
            <th scope="col" class="px-6 py-3">
              Request Date
            </th>
            <th scope="col" class="px-6 py-3">
              Width
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b hover:bg-gray-50">
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <strong>
                {{ "PP202404/655894" }}</strong><br>
              {{ "Hela Clothing (Pvt) Ltd." }}
              <!--              <strong>
                  {{ line1 }}</strong><br>-->
              <!--              {{ line2 }}-->
            </th>
            <td class="px-6 py-4">
              2001142499
            </td>
            <td class="px-6 py-4">
              Laptop PC
            </td>
            <td class="px-6 py-4">
              No
            </td>
            <td class="w-44 px-6 py-4">

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="sub-container col-span-1 grid grid-cols-2">
    <div>
      <span class="text">Production Plan</span>
      <input type="text" name="" id="1" class="form-input">
    </div>
    <div>
      <span class="text">Priority</span>
      <input type="text" name="" id="2" class="form-input">
    </div>
    <div class="col-span-2">
      <span class="text">Delivery Date</span>
      <input type="date" name="" id="3" class="form-input">
    </div>
  </div>
</div>
<!--<app-production-planing-popup #popup class=""></app-production-planing-popup>-->
