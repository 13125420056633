import { Component, inject } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { SearchCustomerComponent, UserProfileComponent } from "../../../../shared";
import { ReportService } from "../../services";
import { exportToCSV } from "../../../../../core/util/util";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";
import {NotificationService} from "../../../../services/notification.service";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'app-reports',
    standalone: true,
    imports: [
        FormsModule,
        SearchCustomerComponent,
        UserProfileComponent
    ],
    templateUrl: './reports.component.html',
    styleUrl: './reports.component.scss'
})
export class ReportsComponent {
    reportService = inject(ReportService);
    startDate: string = '';
    endDate: string = '';
    reportGenerated: boolean = false;
    salesOrderData: any[] = [];
    invoiceData: any[] = [];
    salesOrderReportGenerated: boolean = false;
    invoiceReportGenerated: boolean = false;

    constructor(private dotLoadingServices: DotLoadingServices, private notificationService: NotificationService) {
    }

    generateReport(reportType: 'salesOrder' | 'invoice') {
        if (!this.isDateRangeValid()) {
            this.notificationService.showNotification({
                type: 'error',
                message: 'Please ensure the end date is not earlier than the start date.'
            });
            return;
        }

        this.dotLoadingServices.setLoading(true);

        const reportObservable = reportType === 'salesOrder'
            ? this.reportService.getSalesOrderReport(this.startDate, this.endDate, true)
            : this.reportService.getInvoiceReport(this.startDate, this.endDate, true);

        reportObservable.pipe(
            finalize(() => this.dotLoadingServices.setLoading(false))
        ).subscribe({
            next: (result) => {
                if (reportType === 'salesOrder') {
                    this.salesOrderData = result.data;
                    this.salesOrderReportGenerated = this.salesOrderData.length > 0;
                } else {
                    this.invoiceData = result.data;
                    this.invoiceReportGenerated = this.invoiceData.length > 0;
                }

                const reportGenerated = reportType === 'salesOrder' ? this.salesOrderReportGenerated : this.invoiceReportGenerated;

                if (!reportGenerated) {
                    this.notificationService.showNotification({
                        type: 'error',
                        message: 'No data available for the selected date range. Please try a different period.'
                    });
                } else {
                    this.notificationService.showNotification({
                        type: 'success',
                        message: `${reportType === 'salesOrder' ? 'Sales Order' : 'Invoice'} report is available for download.`,
                        timeout: 60000
                    });
                }
            },
            error: (err) => {
                console.error('Error while fetching report: ', err);
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Failed to generate report. Please try again.'
                });
            }
        });
    }
    onStartDateChange() {
        if (this.endDate && new Date(this.endDate) < new Date(this.startDate)) {
            this.endDate = this.startDate;
        }
    }
    isDateRangeValid(): boolean {
        return !!this.startDate && !!this.endDate && new Date(this.endDate) >= new Date(this.startDate);
    }

    salesOrderReport() {
        const salesOrderReport = this.salesOrderData.map((item: any) => [
            item.orderDate,
            item.salesOrderReference,
            item.customerPoNumber,
            item.customerPoDate,
            item.customerName,
            item.currencyCode,
            item.description,
            item.styleNo,
            item.planCardNumber,
            item.customerReference,
            item.customerReqDate,
            item.unitPrice,
            item.quantity,
            item.packedQuantity,
            item.deliveredQuantity
        ]);
        const headers = [
            'Order Date', 'Sales Order Reference', 'Customer PO Number', 'Customer PO Date',
            'Customer Name', 'Currency Code', 'Description', 'Style No', 'Plan Card Number',
            'Customer Reference', 'Customer Req Date', 'Unit Price', 'Quantity',
            'Packed Quantity', 'Delivered Quantity'
        ];
        salesOrderReport.unshift(headers);
        exportToCSV(salesOrderReport, 'SalesOrderReport');
    }

    invoiceReport() {
        const invoiceReport = this.invoiceData.map((item: any) => [
            // Map invoice data similar to sales order data
            // Adjust the fields according to your invoice data structure
            item.orderDate,
            item.salesOrderReference,
            item.customerPoNumber,
            item.customerPoDate,
            item.customerName,
            item.currencyCode,
            item.description,
            item.styleNo,
            item.planCardNumber,
            item.customerReference,
            item.customerReqDate,
            item.unitPrice,
            item.quantity,
            item.packedQuantity,
            item.deliveredQuantity
        ]);
        const headers = [
            'Order Date', 'Sales Order Reference', 'Customer PO Number', 'Customer PO Date',
            'Customer Name', 'Currency Code', 'Description', 'Style No', 'Plan Card Number',
            'Customer Reference', 'Customer Req Date', 'Unit Price', 'Quantity',
            'Packed Quantity', 'Delivered Quantity'
        ];
        invoiceReport.unshift(headers);
        exportToCSV(invoiceReport, 'InvoiceReport');
    }
}