import {APIRequestResources, CachedAPIRequest} from "../../../../core";
import {BehaviorSubject} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {SalesOrderReport} from "../interface/reports.entity";
import {tap} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ReportService extends CachedAPIRequest {

    private readonly $all = new BehaviorSubject<SalesOrderReport[]>([]);
    all = toSignal(this.$all, {initialValue: []})

    private readonly $active = new BehaviorSubject<SalesOrderReport | undefined>(undefined);
    active = toSignal(this.$active, {initialValue: undefined});

    constructor(protected override http: HttpClient, private router: Router,) {
        super(http, APIRequestResources.ReportService);
    }


    getSalesOrderReport(startDate: string, endDate: string, refresh = false) {
        const searchParams = {
            start_date: startDate,
            end_date: endDate,
        };
        return this.get<SalesOrderReport[]>({
            endpoint: `sales-order`,
            params: searchParams,
        }, refresh ? 'freshness' : 'performance')
            .pipe(
                tap((res) => {
                    this.$all.next(res.data);
                })
            );
    }


    getInvoiceReport(startDate: string, endDate: string, refresh = false) {
        const searchParams = {
            start_date: startDate,
            end_date: endDate,
        };
        return this.get<SalesOrderReport[]>({
            endpoint: `invoice`,
            params: searchParams,
        }, refresh ? 'freshness' : 'performance')
            .pipe(
                tap((res) => {
                    this.$all.next(res.data);
                })
            );
    }

}