import {Injectable} from '@angular/core';
import {CachedAPIRequest} from "../../../../core";
import {BehaviorSubject, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {APIRequestResources, PaginationResponse} from "../../../../core";
import {Invoice, InvoiceDTO, InvoiceResults} from "../interfaces/invoice.entity";
import {DotLoadingServices} from "../../../../core/services/dot-loading.Services";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends CachedAPIRequest {


































































  $all = new BehaviorSubject<InvoiceResults[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<InvoiceDTO | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined})

  private readonly $statistics = new BehaviorSubject<any>(undefined)
  stat = toSignal(this.$statistics, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router, private dotLoadingServices: DotLoadingServices,) {
    super(http, APIRequestResources.InvoiceService)
    this.find({
      start_date: '',
      end_date: '',
      invoice_number: '',
      customer_id: '',
      invoice_type_id: '',
      items_per_page: '10',
      page_number: '1'
    }, true).pipe(take(1)).subscribe()
  }

  find = (searchParams: any, refresh = false) => {
    this.dotLoadingServices.setLoading(true)
    return this.get<PaginationResponse<InvoiceResults[]>>({
      endpoint: `find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data.data)),
        tap((res) => {
          this.dotLoadingServices.setLoading(false)
          const {data, ...obj} = res.data
          this.$statistics.next(obj)
        })
      )
  }


  getById = (id: any, refresh = false) => {
    return this.get<InvoiceDTO>({id}, refresh ? 'freshness' : 'performance')
        .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }


  create = (invoice: Invoice) => {
    return this.post<any>(invoice, {}).pipe(
        tap(() => {
          this.$all.next([])
        })
    );
  }

  initial  = () => {
    this.$active.next(undefined)
    this.$all.next([])
  }

}

