<div class="header-container">
    <div class="grid grid-cols-4 col-span-3 sub-container">
        <div class="form-group w-full col-span-4 justify-center flex">
            <div class="py-1" style="flex-grow: 1;">
                <label class="block">
                    <span class="sr-only">Choose file</span>
                    <input type="file"
                           (change)="fileUpload($event)"
                           accept=".xml"
                           [disabled]="isDownloaded"
                           class="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0
        file:text-sm file:font-[520] file:bg-[#ffcbe1] file:text-[#D41A6A] hover:file:bg-[#930046] hover:file:text-white cursor-pointer"/>
                </label>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-3 col-span-6 sub-container">
        <div class="flex pl-4 ">
            <select class="  input-new min-w-64 " [(ngModel)]="processMode">
                <option value="po_item" selected>Purchase Order Item</option>
                <option value="material_code">Material Code</option>
            </select>
        </div>
        <div class="flex py-1">
            @if (isDownloaded || isFileUploaded) {
                <button class="disabled">Download Items</button>
            } @else {
                <button class="button-new" (click)="download()">Download Items</button>
            }
        </div>
        <div class="flex py-1">
            @if (isDownloaded || isFileUploaded) {
                <button
                        [class]="isSaving ? 'disabled' : 'button-new'"
                        [disabled]="isSaving"
                        (click)="handleSaveClick()">
                    {{ isSaving ? 'Saving...' : 'Save Items' }}
                </button>
            } @else {
                <button class="disabled" title="Please download or upload data first">Save Items</button>
            }
        </div>
    </div>
    <div class="col-span-3 p-6 sub-container">
        <app-user-profile></app-user-profile>
    </div>
</div>

<div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4 rounded-xl min-w-max">
    <div class="bg-white rounded-lg shadow-lg md:col-span-4 lg:col-span-12">
        <div class="p-6">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 bg-gray-50">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Customer Reference
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Description
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Style
                    </th>
                    <th>
                        Quantity
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Portal Price
                    </th>
                    <th scope="col" class="px-6 py-3">
                        System Price
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Customer Request Date
                    </th>
                </tr>
                </thead>
                <tbody>
                    @for (salesOrderItem of itemViewList; track salesOrderItem) {
                        <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer">
                            <th scope="row" class="px-6 py-4">
                                {{ salesOrderItem.customerReference }}
                            </th>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.description }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.styleNo }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.quantity }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.netPrice }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.unitPrice }}
                            </td>
                            <td class="px-6 py-4">
                                {{ salesOrderItem.customerRequestDate }}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="main-container">
    <div class="col-span-2 sub-container grid grid-cols-1">
        <div class="py-2">
            <button [routerLink]="'/sales-order'" class="button-new">Back</button>
        </div>
    </div>
</div>


<div *ngIf="isOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Price Mismatched
                </h3>
                <button  (click)=" closeModal()"  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class="p-4 md:p-5 space-y-4">
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    The price is mismatched for these items. Are you sure you want to save with mismatched prices?
                </p>

            </div>
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button  (click)="handleAccept()" class="text-white bg-pink-600 hover:bg-pink-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-pink-600 dark:hover:bg-pink-700">
                    Save
                </button>
                <button  (click)="closeModal()" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>