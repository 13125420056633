import {inject, Injectable} from "@angular/core";
import {MASPortalApiRequest, MASPortalResources} from "../../../../core";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class DeliveryConfirmationService extends MASPortalApiRequest {

    constructor(protected override http: HttpClient, private router: Router) {
        super(http, MASPortalResources.DeliveryConfirmationService)
    }




}