<div class="main-container">
  <div class="sub-container col-span-1">
    <div>
      <input type="text" name="" id="" class="form-input">
    </div>
  </div>
  <div class="sub-container col-span-2 grid grid-cols-3">
    <div>
      <button class="sub-button">Submit</button>
    </div>
    <div>
      <button class="button">Update</button>
    </div>
    <div>
      <button class="button">Clear</button>
    </div>
  </div>
  <div class="sub-container">
    <app-user-profile></app-user-profile>
  </div>
</div>
<div class="table-container mt-6">
  <div class="sub-container col-span-3">
    <div class="relative overflow-x-auto  rounded-xl ">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
        <thead class="text-xs text-gray-700 bg-gray-50">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
             Plan Card
            </th>
            <th scope="col" class="px-6 py-3">
             Lebel Name
            </th>
            <th scope="col" class="px-6 py-3">
              Style
            </th>
            <th scope="col" class="px-6 py-3">
              Length
            </th>
            <th scope="col" class="px-6 py-3">
              Quantity
             </th>
            <th scope="col" class="px-6 py-3">
              Request Date
            </th>
            <th scope="col" class="px-6 py-3">
              Width
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b hover:bg-gray-50">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input id="checkbox-table-search-2" type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="checkbox-table-search-2" class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              Microsoft Surface Pro
            </th>
            <td class="px-6 py-4">
              White
            </td>
            <td class="px-6 py-4">
              Laptop PC
            </td>
            <td class="px-6 py-4">
              No
            </td>
            <td class="w-44 px-6 py-4">

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="sub-container col-span-1 grid grid-cols-2">
    <div>
      <span class="text">Production Plan</span>
      <input type="text" name="" id="" class="form-input">
    </div>
    <div>
      <span class="text">Priority</span>
      <input type="text" name="" id="" class="form-input">
    </div>
    <div>
      <span class="text">Delivery Date</span>
      <input type="date" name="" id="" class="form-input">
    </div>
    <div>
      <button class="sub-button mt-5">Approve</button>
    </div>
  </div>
</div>
