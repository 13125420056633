<div class="grid grid-cols-1 gap-4 px-4 py-2 md:grid-cols-12 rounded-xl">
    <div class="bg-white rounded-xl flex justify-center items-center p-6 md:hidden">
        <app-user-profile></app-user-profile>
    </div>
    <!-- Main content area -->
    <div class="bg-white rounded-xl flex flex-col justify-center items-center p-6 md:col-span-9">
        <form class="space-y-4 w-full">
            <div class="grid grid-cols-1 gap-6">
                @if (salesOrder) {
                    <div class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 items-center">
                        <div class="grid grid-cols-2 md:grid-cols-5 gap-4 w-full">
                            <div>
                                <p class="text-m font-extrabold">Sales Order Reference</p>
                                <div class="text-sm">{{ salesOrder.salesOrderReference }}</div>
                            </div>
                            <div>
                                <p class="text-m font-extrabold">Customer Name</p>
                                <div class="text-sm">{{ salesOrder.customer.customerName }}</div>
                            </div>
                            <div>
                                <p class="text-m font-extrabold">Po Date</p>
                                <div class="text-sm">{{ salesOrder.poDate }}</div>
                            </div>
                            <div>
                                <p class="text-m font-extrabold">PO No</p>
                                <div class="text-sm">{{ salesOrder.poNumber }}</div>
                            </div>
                            <div>
                                <p class="text-m font-extrabold">Value</p>
                                <div class="text-sm">{{ salesOrder.poValue }}</div>
                            </div>
                        </div>
                        <div class="flex space-x-2 w-full md:w-auto mt-4 md:mt-0">
                            <button class="disabled" disabled (click)="currentView.set('create')">Add Item</button>
                            @if (salesOrderItem) {
                                <button class="disabled" disabled (click)="currentView.set('update')">Update Item</button>
                            } @else {
                                <button class="disabled">Update Item</button>
                            }
                        </div>
                    </div>
                }
            </div>
        </form>
    </div>
    <div class="bg-white rounded-xl col-span-3 items-center h-full p-6">
        <app-user-profile></app-user-profile>
    </div>
</div>


@switch (currentView()) {
    @case ('result') {
        <app-sales-order-item-grid [defaultSearchParams]="searchParams"></app-sales-order-item-grid>
    }
    @case ('create') {
        <app-sales-order-item-form></app-sales-order-item-form>
    }
    @case ('update') {
        <app-sales-order-item-form></app-sales-order-item-form>
    }
    @default {

    }
}
<div class="main-container">
    <div class="col-span-1 sub-container grid grid-cols-1">
        <div class="py-2">
            <button [routerLink]="'/sales-order'"  class="button-new">Back</button>
        </div>
    </div>
</div>


