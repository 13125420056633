import {Component, effect, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {SearchCustomerComponent, TablePaginationComponent, UserProfileComponent} from "../../../../shared";
import {SalesOrderService} from "../../../../services";
import {SalesOrderFormComponent} from "../../../sales-order/shared";
import {OrderDownloadService} from "../../services";
import {CustomerCategoryService} from "../../../customer-category";
import {SearchPurchaseOrder} from "../../interfaces/mas-purchase-order.entity";
import {SalesOrderItem} from "../../interfaces/sales-order-item.entity";
import {CustomerReferenceService} from "../../../customer-reference";
import {SearchCustomerReference} from "../../../customer-reference/interface/customer-reference.entity";
import {SearchCustomerCategory} from "../../../customer-category/interfaces/customer-category.entity";
import {Router, RouterLink} from "@angular/router";
import {SalesOrderItemService} from "../../../sales-order";
import {NotificationService} from "../../../../services/notification.service";
import {NgIf} from "@angular/common";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";



@Component({
    selector: 'app-receive-order',
    standalone: true,
    imports: [
        FormsModule,
        SearchCustomerComponent,
        UserProfileComponent,
        SalesOrderFormComponent,
        TablePaginationComponent,
        NgIf,
        RouterLink
    ],
    templateUrl: './receive-order.component.html',
    styleUrl: './receive-order.component.scss'
})
export class ReceiveOrderComponent implements OnInit, OnDestroy {

    salesOrderService = inject(SalesOrderService)
    orderDownloadService = inject(OrderDownloadService);
    customerCategoryService = inject(CustomerCategoryService)
    customerReferenceService = inject(CustomerReferenceService)
    salesOrderItemService = inject(SalesOrderItemService)

    isOpen = false;
    openModal() {
        this.isOpen = true;
    }

    closeModal() {
        this.isOpen = false;
    }

    handleAccept() {
        this.collectDataToSave();
        this.closeModal();

    }


    get salesOrder() {
        return this.salesOrderService.active()
    }

    itemViewList: SalesOrderItem[] = []
    poItemViewList: SalesOrderItem[] = []
    materialViewList: SalesOrderItem[] = []
    selectedFile: File | undefined;
    isDownloaded = false;
    isFileUploaded = false;
    hasDataToSave = false;
    isSaving = false;

    processMode = signal<string>('po_item')

    searchPurchaseOrderParams: SearchPurchaseOrder = {
        po_number: '',
        start_date: '',
        end_date: '',
        merch_name: '',
        merch_email: '',
        rev: '',
        system: 'PDM300',
        user_name: 'v_Chelsea.Hsu'
    }

    searchCustomerReferenceParams: SearchCustomerReference = {
        customer_id: '-1',
        customer_reference: '',
        currency_id: '-1',
        product_sample_id: '-1',
    }

    searchCustomerCategoryParams: SearchCustomerCategory = {
        customer_id: '-1',
        filter_key: '',
        system_code: '',
        factory_code: '',
    }

    constructor(
        private router: Router,
        private dotLoadingServices: DotLoadingServices,
        private notificationService: NotificationService,
    ) {
        effect(() => {
            switch (this.processMode()) {
                case 'po_item':
                    this.itemViewList = [...this.poItemViewList]
                    break
                case 'material_code':
                    this.itemViewList = [...this.materialViewList]
                    break
            }
        }, {allowSignalWrites: true});
        effect(() => {
            const purchaseOrders = this.orderDownloadService.all()
            this.processXmlData(purchaseOrders)
            this.processMode.set('po_item')

        }, {allowSignalWrites: true});
    }

    download = () => {
        if (this.isDownloaded || this.isFileUploaded) {
            return;
        }
        this.dotLoadingServices.setLoading(true);
        this.orderDownloadService.find(this.searchPurchaseOrderParams).subscribe({
            next: () => {
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'success',
                    message: 'Sales Order Item Downloaded Successfully'
                });
                this.isDownloaded = true;
            },
            error: (err) => {
                console.error(err);
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Error Downloading Sales Order Item'
                });
            },
            complete: () => this.dotLoadingServices.setLoading(false)
        });
    }

    fileUpload(event: any) {
        this.selectedFile = event.target.files[0];
        this.dotLoadingServices.setLoading(true);
        this.orderDownloadService.uploadFile(this.selectedFile).subscribe({
            next: (res) => {
                const xmlData = res.data
                this.clearCurrentData()
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'success',
                    message: 'Purchase Orders Extracted Successfully!'
                });
                this.processXmlData(xmlData)
                this.isFileUploaded = true;
                this.checkPricesAndNotify();
            },
            error: (err) => {
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Error While Reading the File!'
                });
                console.error(err)
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Error uploading file'
                });
            }
        })
    }

    checkPricesAndNotify(): boolean {
        const mismatches = this.itemViewList
            .filter(item => !this.isPriceSame(item))
            .map((item, index) => `Item ${index + 1}: Unit Price ${item.unitPrice} ≠ Net Price ${item.netPrice}`);

        if (mismatches.length > 0) {
            const message = `Price mismatches found in ${mismatches.length} item(s). Please review again.`;
            this.notificationService.showNotification({
                type: 'error',
                message: message,
                timeout: 10000
            });
            return false;
        }
        return true;
    }

    clearCurrentData = () => {
        this.itemViewList = [];
        this.poItemViewList = [];
        this.materialViewList = [];
        this.isDownloaded = false;
        this.isFileUploaded = false;
        this.hasDataToSave = false;
        this.isSaving = false;
    }

    isPriceSame = (salesOrderItem: SalesOrderItem): boolean => {
        return Number(salesOrderItem.unitPrice) === Number(salesOrderItem.netPrice);
    }

    allPricesAreSame(): boolean {
        return this.itemViewList.every(item => this.isPriceSame(item));
    }

    collectDataToSave = () => {
        if (this.itemViewList.length === 0) {
            this.notificationService.showNotification({
                type: 'error',
                message: 'No data to save!'
            });
            return;
        } else {
            this.saveData(this.itemViewList);
        }
    }


    handleSaveClick(): void {
        if (this.itemViewList.length === 0) {
            this.notificationService.showNotification({
                type: 'error',
                message: 'No data to save!'
            });
            return;
        }
        if (!this.allPricesAreSame()) {
           this.isOpen=true
        } else {
            this.collectDataToSave();
        }
    }


    saveData = (part: any[]) => {
        const itemCount = part.length;
        this.isSaving = true;
        this.dotLoadingServices.setLoading(true);
        this.salesOrderItemService.create(part).subscribe({
            next: () => {

                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'success',
                    message: `Successfully saved ${itemCount} ${itemCount === 1 ? 'item' : 'items'} to the sales order.`,
                    timeout: 5000
                });
                setTimeout(() => {
                    this.router.navigate(['/sales-order-item']);
                }, 3000);
            },
            error: (err) => {
                this.isSaving = false;
                this.dotLoadingServices.setLoading(false);
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Failed to save this sales order items!'
                });
                console.error(err);
            }
        });
    }

    async ngOnInit(): Promise<void> {
        if (!this.salesOrder) {
            this.router.navigate(['/sales-order'])
        } else {
            this.searchCustomerCategoryParams.customer_id = String(this.salesOrder.customer.customerId)
            this.customerCategoryService.find(this.searchCustomerCategoryParams, false).subscribe();
            this.searchCustomerReferenceParams.customer_id = String(this.salesOrder.customer.customerId)
            this.searchCustomerReferenceParams.currency_id = String(this.salesOrder.currency.currencyId)
            this.customerReferenceService.find(this.searchCustomerReferenceParams, true).subscribe()
            this.searchPurchaseOrderParams.po_number = this.salesOrder.poNumber
        }
    }

    ngOnDestroy(): void {
        this.orderDownloadService.initial();
    }

    processXmlData = (purchaseOrders: any) => {
        if (purchaseOrders && purchaseOrders.length == 1) {
            const purchaseOrder = purchaseOrders[0]
            const materialItemList: Record<string, SalesOrderItem> = {}
            purchaseOrder.purchaseOrderItems.forEach((purchaseOrderItem: any) => {
                const systemPriceData = this.filterPriceBatch(purchaseOrderItem.materialCode, purchaseOrderItem.quantity)
                let soiStatus = ''
                let cost = 0
                let unitPrice = 0
                let productDesignId = -1
                if (!systemPriceData) {
                    soiStatus = `PNF` // Price Not Found
                } else if (systemPriceData.length > 1) {
                    soiStatus = `MPF` // Multiple Prices Found
                } else if (systemPriceData.length == 1) {
                    cost = systemPriceData[0].productCost / 1000
                    // unitPrice = systemPriceData[0].productPrice
                    unitPrice = parseFloat((systemPriceData[0].productPrice / 1000).toFixed(5))
                    productDesignId = systemPriceData[0].productDesignId
                } else {
                    soiStatus = `ERROR`
                }


                // console.log('Unit Price'+unitPrice)
                // console.log('product price'+systemPriceData[0].productPrice)
                // console.log(parseFloat((systemPriceData[0].productPrice  / 1000).toFixed(5)))

                const salesOrderItem: SalesOrderItem = {
                    salesOrderId: (this.salesOrder) ? this.salesOrder.salesOrderId : -1,
                    productDesignId: productDesignId,
                    description: `${purchaseOrderItem.materialCode} - ${purchaseOrderItem.materialDescription}`,
                    styleNo: purchaseOrderItem.customerStyle,
                    customerReference: `${purchaseOrder.purchaseOrderNumber}::${purchaseOrderItem.purchaseOrderItem}_${purchaseOrderItem.salesOrder}::${purchaseOrderItem.salesOrderItem}`,
                    unitId: 1,
                    cost: cost,
                    unitPrice: Number(unitPrice.toFixed(5)),
                    netPrice: Number((Math.floor((purchaseOrderItem.netPrice / purchaseOrderItem.per) * 100000) / 100000).toFixed(5)),
                    quantity: purchaseOrderItem.quantity,
                    customerRequestDate: purchaseOrderItem.deliveryDate,
                    deliveryAddressId: (this.salesOrder?.deliveryAddress) ? this.salesOrder.deliveryAddress?.addressId : -1,
                    remarks: purchaseOrderItem.cpo,
                    salesOrderItemStatus: soiStatus,
                    salesOrderItemBreakdowns: purchaseOrderItem.purchaseOrderItemBreakdowns.map((purchaseOrderItemBreakdown: any) => {
                        return {
                            size: purchaseOrderItemBreakdown.size,
                            quantity: purchaseOrderItemBreakdown.quantity,/**/
                            salesOrderItemBreakdownDetail: {
                                colorCode: purchaseOrderItem.colorCode,
                                materialCode: purchaseOrderItem.materialCode,
                                materialDescription: purchaseOrderItem.materialDescription,
                                purchaseOrderItem: purchaseOrderItem.purchaseOrderItem,
                                salesOrder: purchaseOrderItem.salesOrder,
                                salesOrderItem: purchaseOrderItem.salesOrderItem
                            }
                        }
                    }),

                }
                // console.log(salesOrderItem.unitPrice)
                // console.log(salesOrderItem.netPrice)
                if (!materialItemList[purchaseOrderItem.materialCode]) {
                    materialItemList[purchaseOrderItem.materialCode] = {...salesOrderItem}
                    materialItemList[purchaseOrderItem.materialCode].styleNo = ''
                } else {
                    materialItemList[purchaseOrderItem.materialCode].styleNo = ''
                    materialItemList[purchaseOrderItem.materialCode].quantity += salesOrderItem.quantity
                    materialItemList[purchaseOrderItem.materialCode].salesOrderItemBreakdowns = [...materialItemList[purchaseOrderItem.materialCode].salesOrderItemBreakdowns, ...salesOrderItem.salesOrderItemBreakdowns];
                }
                this.poItemViewList.push(salesOrderItem)
            })

            this.materialViewList = Object.values(materialItemList)
        }
        this.itemViewList = [...this.poItemViewList]
    }


    filterPriceBatch = (materialCode: string, quantity: number) => {
        return this.customerReferenceService.all().filter(item => {
            return (
                item.customerReference == materialCode &&
                item.minQuantity <= quantity &&
                item.maxQuantity >= quantity
            );
        })
    }
}



