import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {PackingListService} from "../../../packing-list";
import {
    CreatePackingListFormComponent
} from "../../../packing-list/shared/create-packing-list-form/create-packing-list-form.component";
import {FormsModule} from "@angular/forms";
import {SalesOrderItemGridComponent} from "../../../sales-order/shared";
import {SearchCustomerComponent, UserProfileComponent} from "../../../../shared";
import {CustomersDTO, Invoice, SalesOrderItem, SalesOrderItemBreakdown} from "../../interfaces/invoice.entity";
import {NgForOf} from "@angular/common";
import {InvoiceService} from "../../services";
import {Router, RouterLink} from "@angular/router";
import {NotificationService} from "../../../../services/notification.service";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";
import {take} from "rxjs";

@Component({
    selector: 'app-invoice-create',
    standalone: true,
    imports: [
        CreatePackingListFormComponent,
        FormsModule,
        SalesOrderItemGridComponent,
        UserProfileComponent,
        NgForOf,
        SearchCustomerComponent,
        RouterLink
    ],
    templateUrl: './invoice-create.component.html',
    styleUrl: './invoice-create.component.scss'
})
export class InvoiceCreateComponent implements OnInit {

    packingListService: PackingListService = inject(PackingListService);
    invoiceService: InvoiceService = inject(InvoiceService);

    previewList: SalesOrderItem[] = [];
    customers: CustomersDTO[] = []
    salesOrderItems: SalesOrderItem[] = []
    selectedItems = new Set<number>();

    subTotal = signal<number>(0);
    invoiceValue = signal<number>(0);
    selectedCustomerId = signal<number>(-1);
    planCardNoFilter = signal<string>('');
    customerPoFilter = signal<string>('');

    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private dotLoadingServices: DotLoadingServices) {
        effect(() => {
            this.initializeComponentData()
        });
    }


    calculate = () => {
        let invoiceValue = 0;
        this.previewList.forEach((salesOrderItem: SalesOrderItem) => {
            if (salesOrderItem.selected) {
                invoiceValue += salesOrderItem.unitPrice * salesOrderItem.packedQTY;
                this.selectedItems.add(salesOrderItem.salesOrderItemId);
            } else {
                this.selectedItems.delete(salesOrderItem.salesOrderItemId);
            }
        });
        if (invoiceValue % 1 !== 0) {
            invoiceValue = Math.ceil(invoiceValue * 100) / 100;
        }
        this.subTotal.set(invoiceValue);
        this.invoiceValue.set(invoiceValue);
    };

    updateSelectedCustomer = (event: Event) => {
        const target = event.target as HTMLSelectElement;
        this.selectedCustomerId.set(Number(target.value));
    };

    updatePlanCardNo = (event: Event) => {
        const target = event.target as HTMLInputElement;
        this.planCardNoFilter.set(target.value);
    };

    updateCustomerPo = (event: Event) => {
        const target = event.target as HTMLInputElement;
        this.customerPoFilter.set(target.value);
    };


    save = () => {
        this.dotLoadingServices.setLoading(true);
        const selectedItems = this.previewList.filter(item => item.selected);
        if (selectedItems.length === 0) {
            this.notificationService.showNotification({
                type: 'error',
                message: 'Please select at least one item to create an invoice.'
            });
            this.dotLoadingServices.setLoading(false);
            return;
        }
        const invoiceData: Invoice = {
            invoiceCustomerPO: selectedItems[0]?.poNumber || '',
            invoiceValue: selectedItems.reduce((total, item) => total + item.unitPrice * item.quantity, 0),
            taxValue: 0,
            invoiceQuantity: selectedItems.reduce((total, item) => total + item.quantity, 0),
            customerId: selectedItems[0]?.customerId || 0,
            currencyId: selectedItems[0]?.currencyId || 0,
            invoiceTypeId: selectedItems[0]?.invoiceTypeId || 0,
            invoiceAddressId: selectedItems[0]?.invoiceAddressId || 0,
            deliveryAddressId: selectedItems[0]?.deliveryAddressId || 0,
            packingLists: selectedItems.flatMap(item =>
                item.packingLists.map(packingList => ({
                    packingListId: packingList.packingListId
                }))
            )
        };
        this.invoiceService.create(invoiceData).subscribe({
            next: (res) => {
                const successMessage = `Invoice successfully created for customer
                ${this.customers.find(customer => customer.id === invoiceData.customerId)?.name},
                PO Number: ${invoiceData.invoiceCustomerPO || 'N/A'}`;
                this.notificationService.showNotification({
                    type: 'success',
                    message: successMessage
                });
                this.navigateToView()
            },
            error: (err) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: 'Failed to save invoice. Please try again.'
                });
                this.dotLoadingServices.setLoading(false);
                console.error(err);
            }
        });
    };

    navigateToView() {
        this.dotLoadingServices.setLoading(true);
        this.invoiceService.find({
            start_date: '',
            end_date: '',
            invoice_number: '',
            customer_id: '',
            invoice_type_id: '',
            items_per_page: '10',
            page_number: '1'
        }, true).pipe(take(1)).subscribe({
            next: () => {
                this.dotLoadingServices.setLoading(false);
                this.router.navigate(['/invoice-view']);
            },
        });
    }

    ngOnInit(): void {
        this.initializeComponentData();
    }

    initializeComponentData() {
        const initialDate = this.packingListService.all();
        if (initialDate) {
            const customers: Record<number, string> = {};
            for (const packingList of this.packingListService.all()) {
                const {customerId, customerName} = packingList.salesOrderItem.salesOrder.customer;
                if (!customers[customerId]) {
                    customers[customerId] = customerName;
                    this.customers.push({
                        id: customerId,
                        name: customerName
                    })

                }
            }

            const salesOrderItems: Record<number, SalesOrderItem> = {};
            for (const packingList of this.packingListService.all()) {
                if (this.selectedCustomerId() !== -1 && packingList.salesOrderItem.salesOrder.customer.customerId !== this.selectedCustomerId()) {
                    continue;
                }

                const planCardNoFilter = this.planCardNoFilter().toLowerCase();
                const customerPoFilter = this.customerPoFilter().toLowerCase();
                if (planCardNoFilter && !packingList.salesOrderItem.planCardNumber.toLowerCase().includes(planCardNoFilter)) {
                    continue;
                }
                if (customerPoFilter && !packingList.salesOrderItem.salesOrder.poNumber.toLowerCase().includes(customerPoFilter)) {
                    continue;
                }

                const salesOrderItemBreakdowns: Record<number, SalesOrderItemBreakdown> = salesOrderItems[packingList.salesOrderItem.salesOrderItemId] ? salesOrderItems[packingList.salesOrderItem.salesOrderItemId].salesOrderItemBreakdowns : {};

                for (const breakdown of packingList.packingListBreakdowns) {
                    const {salesOrderItemBreakdown, packQuantity} = breakdown;
                    if (!salesOrderItemBreakdowns[salesOrderItemBreakdown.salesOrderItemBreakdownId]) {
                        salesOrderItemBreakdowns[salesOrderItemBreakdown.salesOrderItemBreakdownId] = {
                            salesOrderItemBreakdownId: salesOrderItemBreakdown.salesOrderItemBreakdownId,
                            size: salesOrderItemBreakdown.size,
                            quantity: packQuantity,
                            salesOrderItemBreakdownDetails: salesOrderItemBreakdown.salesOrderItemBreakdownDetails
                        };
                    } else {
                        salesOrderItemBreakdowns[salesOrderItemBreakdown.salesOrderItemBreakdownId].quantity = salesOrderItemBreakdowns[salesOrderItemBreakdown.salesOrderItemBreakdownId].quantity + packQuantity;
                    }
                }

                if (!salesOrderItems[packingList.salesOrderItem.salesOrderItemId]) {
                    const packingLists = [];
                    packingLists.push(packingList);
                    salesOrderItems[packingList.salesOrderItem.salesOrderItemId] = {
                        salesOrderItemId: packingList.salesOrderItem.salesOrderItemId,
                        description: packingList.salesOrderItem.description,
                        planCardNumber: packingList.salesOrderItem.planCardNumber,
                        poNumber: packingList.salesOrderItem.salesOrder.poNumber,
                        unitPrice: packingList.salesOrderItem.unitPrice,
                        quantity: packingList.salesOrderItem.quantity,
                        customerId: packingList.salesOrderItem.salesOrder.customer.customerId,
                        currencyId: packingList.salesOrderItem.salesOrder.currencyId,
                        deliveryAddressId: packingList.salesOrderItem.delReqAddress.addressId,
                        invoiceTypeId: packingList.salesOrderItem.salesOrder.customer.invoiceTypeId,
                        invoiceAddressId: packingList.salesOrderItem.salesOrder.invoiceAddressId,
                        deliveryAddressText: packingList.salesOrderItem.delReqAddress.address,
                        packingLists: packingLists,
                        salesOrderItemBreakdowns: {},
                        selected: this.selectedItems.has(packingList.salesOrderItem.salesOrderItemId),
                        packedQTY: packingList.packingListBreakdowns.reduce((total, item) => total + item.packQuantity, 0)
                    };

                } else {
                    salesOrderItems[packingList.salesOrderItem.salesOrderItemId].packingLists.push(packingList);
                }
            }
            this.salesOrderItems = Object.values(salesOrderItems);
            this.salesOrderItems.map(salesOrderItem => salesOrderItem.salesOrderItemBreakdowns = Object.values(salesOrderItem.salesOrderItemBreakdowns));
            this.previewList = [...this.salesOrderItems];

        }
    }
}