<div class="header-container">
    <div class="grid grid-cols-4 col-span-3 sub-container">
        <div class="form-group w-full col-span-4 justify-center flex">
            <div class="py-1" style="flex-grow: 1;">
                <input type="text" class="input-new w-full" placeholder="Enter PP Number" [(ngModel)]="searchValue" name="searchValue" style="width: 100%;">
            </div>
            <div class="py-1">
                <button class="button-new" (click)="search()" style="width: 100px;">Search</button>
            </div>
        </div>
    </div>


    <div class="grid grid-cols-3 col-span-6 sub-container">
        @if (salesOrderItem) {
            <div class="flex">
                <div>
                    <p class="text-m font-extrabold">Sales Order Reference</p>
                    <p class="text-sm text-gray-800 pl-5">{{ salesOrderItem.salesOrder.salesOrderReference }}</p>
                </div>
            </div>
            <div class="flex">
                <div>
                    <p class="text-m font-extrabold">Plan Card No</p>
                    <p class="text-sm text-gray-800 pl-5">{{ salesOrderItem.planCardNo }}</p>
                </div>
            </div>
            <div class="flex">
                <div>
                    <p class="text-m font-extrabold">Description</p>
                    <p class="text-sm text-gray-800 pl-5">{{ salesOrderItem.description }}</p>
                </div>
            </div>
        } @else {

            <div class="flex">
                <div>
                    <p class="text-m font-extrabold">Sales Order Reference</p>
                    <p class="text-sm text-gray-800 pl-5">N/A</p>
                </div>
            </div>
            <div class="flex">
                <div>
                    <p class="text-m font-extrabold">Plan Card No</p>
                    <p class="text-sm text-gray-800 pl-5">N/A</p>
                </div>
            </div>
            <div class="flex">
                <div>
                    <p class="text-m font-extrabold">Description</p>
                    <p class="text-sm text-gray-800 pl-5">N/A</p>
                </div>
            </div>

        }
    </div>
    <div class="col-span-3 p-6 sub-container">
        <app-user-profile></app-user-profile>
    </div>
</div>

@switch (currentView()) {
    @case ('sales_order_items') {
        <app-sales-order-item-grid [defaultSearchParams]="searchParams"></app-sales-order-item-grid>
    }
    @case ('create_package_form') {
        <app-create-packing-list-form></app-create-packing-list-form>
    }

}
