import {Component} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {TablePaginationComponent} from "../../../../shared";

@Component({
  selector: 'app-packing-list-grid',
  standalone: true,
  imports: [
    FormsModule,
    TablePaginationComponent
  ],
  templateUrl: './packing-list-grid.component.html',
  styleUrl: './packing-list-grid.component.scss'
})
export class PackingListGridComponent {

}
