import {Component} from '@angular/core';
import {UserProfileComponent} from "../../../../shared/common/user-profile/user-profile.component";
import {
    ProductionPlaningPopupComponent
} from "../../shared/production-planing-popup/production-planing-popup.component";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-production-planning',
    standalone: true,
    templateUrl: './production-planning.component.html',
    styleUrl: './production-planning.component.scss',
  imports: [UserProfileComponent, ProductionPlaningPopupComponent, NgClass]
})
export class ProductionPlanningComponent {
  // line1: string = "PP202404/655894";
  // line2: string = "Hela Clothing (Pvt) Ltd.";
}
