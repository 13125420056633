import {Component, effect, inject, input} from '@angular/core';
import {TablePaginationComponent} from "../../../../shared";
import {SalesOrderItemService} from "../../../sales-order";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";
import {SearchSalesOrderItem} from "../../../sales-order/interfaces/sales-order-item.entity";
import {finalize} from "rxjs/operators";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'app-sales-order-item-home-grid',
    standalone: true,
    imports: [
        TablePaginationComponent
    ],
    templateUrl: './sales-order-item-home-grid.component.html',
    styleUrl: './sales-order-item-home-grid.component.scss'
})
export class SalesOrderItemHomeGridComponent {
    salesOrderItemService = inject(SalesOrderItemService);
    dotLoadingServices = inject(DotLoadingServices);

    ppNumber = input.required<any>();

    totalItems: number = 0;
    itemsPerPage: number = 10;

    searchParams: SearchSalesOrderItem = {
        sales_order_id: '-1',
        plan_card_number: '',
        customer_reference: '',
        artwork_reference: '',
        description: '',
        customer_id: '-1',
        sample_id: '-1',
        items_per_page: '10',
        page_number: '1'
    };


    constructor(private notificationService: NotificationService) {
        effect(() => {
            this.searchParams.plan_card_number = this.ppNumber()
            this.fetchSalesOrdersItem(this.searchParams);
        }, {allowSignalWrites: true});

    }

    fetchSalesOrdersItem = (searchParams: SearchSalesOrderItem) => {
        this.searchParams.plan_card_number = this.ppNumber();
        this.dotLoadingServices.setLoading(true);
        this.salesOrderItemService.find(searchParams, true)
            .pipe(
                finalize(() => this.dotLoadingServices.setLoading(false))
            )
            .subscribe({
                next: (res) => {
                    this.totalItems = res.data.totalItems;
                    this.itemsPerPage = res.data.itemsPerPage;

                    if (!this.totalItems) {
                        this.notificationService.showNotification({
                            type: 'error',
                            message: 'No sales order items found'
                        });
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.notificationService.showNotification({
                        type: 'error',
                        message: 'An error occurred while fetching sales order items'
                    });
                }
            });
    }

    onPageChange(pageNumber: number) {
        this.fetchSalesOrdersItem({
            ...this.searchParams,
            page_number: String(pageNumber)
        });
    }


}


