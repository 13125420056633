import {Component, effect, inject, input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {TablePaginationComponent} from "../../../../shared";
import {finalize} from "rxjs/operators";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";
import {SearchSalesOrder} from "../../../sales-order/interfaces/sales-order.entity";
import {SalesOrderService} from "../../../sales-order";
import {NotificationService} from "../../../../services/notification.service";



@Component({
    selector: 'app-sales-order-home-grid',
    standalone: true,
    imports: [
        FormsModule,
        TablePaginationComponent
    ],
    templateUrl: './sales-order-home-grid.component.html',
    styleUrl: './sales-order-home-grid.component.scss'
})
export class SalesOrderHomeGridComponent implements OnInit{

    salesOrderService = inject(SalesOrderService);
    dotLoadingServices = inject(DotLoadingServices);

    searchResult = input.required<any>();

    totalItems: number = 0;
    itemsPerPage: number = 10;

    searchParams: SearchSalesOrder = {
        sales_order_date: '',
        sales_order_reference: '',
        po_number: '',
        customer_id: '-1',
        items_per_page: '10',
        page_number: '1'
    };

    constructor( private notificationService: NotificationService,) {
        effect(() => {
            this.searchParams.po_number = this.searchResult()
            this.fetchSalesOrders(this.searchParams);
        }, {allowSignalWrites: true});

    }
    fetchSalesOrders = (searchParams: SearchSalesOrder) => {
        this.searchParams.po_number = this.searchResult();
        this.dotLoadingServices.setLoading(true);
        this.salesOrderService.find(searchParams, true)
            .pipe(
                finalize(() => this.dotLoadingServices.setLoading(false))
            )
            .subscribe({
                next: (res) => {
                    this.totalItems = res.data.totalItems;
                    this.itemsPerPage = res.data.itemsPerPage;

                    if (!this.totalItems) {
                        this.notificationService.showNotification({
                            type: 'error',
                            message: 'No sales orders found'
                        });
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.notificationService.showNotification({
                        type: 'error',
                        message: 'An error occurred while fetching sales orders'
                    });
                }
            });
    }

    onPageChange(pageNumber: number) {
        this.fetchSalesOrders({
            ...this.searchParams,
            page_number: String(pageNumber)
        });
    }

    ngOnInit(): void {

    }



}
