<div class="header-container">
    <div class="sub-container md:hidden col-span-12 md:col-span-3">
        <app-user-profile></app-user-profile>
    </div>

    <div class="sub-container col-span-12 md:col-span-9">
        <form class="space-y-4 w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <select class="input-new" (change)="updateSelectedCustomer($event)">
                    <option value="-1"> - ALL -</option>
                    @for (customer of customers; track customer) {
                        <option value="{{ customer.id }}"> {{ customer.name }}</option>
                    }
                </select>
                <div>
                    <input type="search" class="input-new" placeholder="Plan Card No" (input)="updatePlanCardNo($event)">
                </div>
                <div>
                    <input type="search" class="input-new" placeholder="Customer PO" (input)="updateCustomerPo($event)">
                </div>
                <div class="flex space-x-2">
                    <button type="button" class="button-new" (click)="navigateToView()">View Invoice</button>
                    @if (selectedItems.size > 0) {
                        <button type="submit" (click)="save()" class="button-new">Save Invoice</button>
                    } @else {
                        <button type="submit" class="disabled" disabled>Save Invoice</button>
                    }
                </div>
            </div>
        </form>
    </div>

    <div class="sub-container col-span-12 md:col-span-3 hidden md:flex items-center h-full">
        <app-user-profile></app-user-profile>
    </div>
</div>

<div class="main-container">
    <div class="col-span-12 md:col-span-9">
        <div class="bg-white rounded-lg shadow-lg p-6">
            <div class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3">Select</th>
                        <th scope="col" class="px-6 py-3">Plan Card Number</th>
                        <th scope="col" class="px-6 py-3">Customer PO</th>
                        <th scope="col" class="px-6 py-3">Description</th>
                        <th scope="col" class="px-6 py-3">Item Quantity</th>
                        <th scope="col" class="px-6 py-3">Delivery Address</th>
                    </tr>
                    </thead>
                    <tbody>
                        @for (salesOrderItem of salesOrderItems; track salesOrderItem) {
                            <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer">
                                <td class="w-4 p-4">
                                    <div class="flex items-center">
                                        <input id="checkbox-table-search-2" name="salesOrderId" type="checkbox"
                                               [value]="salesOrderItem.salesOrderItemId"
                                               [(ngModel)]="salesOrderItem.selected"
                                               (change)="calculate()"
                                               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label for="checkbox-table-search-2" class="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-900">
                                    {{ salesOrderItem.planCardNumber }}
                                </th>
                                <td class="px-6 py-4">{{ salesOrderItem.poNumber }}</td>
                                <td class="px-6 py-4">{{ salesOrderItem.description }}</td>
                                <td class="px-6 py-4">{{salesOrderItem.packedQTY}}</td>
                                <td class="px-6 py-4">{{ salesOrderItem.deliveryAddressText }}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="col-span-12 md:col-span-3">
        <div class="bg-white rounded-xl shadow-lg p-6">
            <div class="border-b-2 border-gray-200 mb-4">
                <h2 class="text-xl font-bold text-gray-800">Invoice Summary</h2>
            </div>
            <div class="flex justify-between mb-2">
                <span class="text-gray-600">Subtotal:</span>
                <span class="text-gray-900 font-semibold">{{ subTotal() }}</span>
            </div>
            <div class="flex justify-between">
                <span class="text-gray-600">Invoice Total:</span>
                <span class="text-gray-900 font-semibold">{{ invoiceValue() }}</span>
            </div>
        </div>
    </div>
</div>
